import React from 'react';
import { Layout, Typography, Divider } from 'antd';
import styles from '../../../styles/screen/Screen.module.css';
import HeaderComponent from '../../Header/Header';
import { AnalyticsEvent, AnalyticsEventName } from '../../../analytics/AnalyticsEvent';
import Banner from './Banner/Banner';
import { LOGO_SIZE } from '../../../config/constants';
import Auth from '../../../auth/Auth';
import { useGoogleOneTap } from '../../../auth/google';
import Config from '../../../config/config';

const { Content, Footer } = Layout;
const { Link } = Typography;

interface ScreenProps {
    bottomSeperator: boolean;
    logoSize?: number;
    showBanner?: boolean;
    setShowBanner: (show: boolean) => void;
    hasFooter?: boolean;
    children: React.ReactNode;
}

const Screen: React.FC<ScreenProps> = ({
    bottomSeperator,
    logoSize = LOGO_SIZE,
    showBanner = false,
    setShowBanner,
    hasFooter = false,
    children,
}) => {
    const [isLoggedIn, setIsLoggedIn] = React.useState(Auth.loggedIn);
    const [oneTapEnabled, setOneTapEnabled] = React.useState<boolean>(!isLoggedIn);

    function onBannerClose() {
        setShowBanner(false);
        AnalyticsEvent.create(AnalyticsEventName.BANNER_CLOSE_X_BUTTON).mixpanelTrack();
    }

    function onLogoClick() {
        AnalyticsEvent.create(AnalyticsEventName.BUTTON_CLICKED_LOGO_HOME).mixpanelTrack();

        /* Remove the URL params */
        window.history.replaceState({}, '', window.location.origin);
        /* Reload the page to reset sessionID and other shit one day*/
        window.location.reload();
    }

    function handleContactUsClicked() {
        AnalyticsEvent.create(AnalyticsEventName.FOOTER_CONTACT_US_LINK_CLICK).mixpanelTrack();
    }

    const onLogin = () => {
        setIsLoggedIn(true);
        setOneTapEnabled(false);
    };

    useGoogleOneTap(onLogin, oneTapEnabled);

    const onSignOut = () => {
        Auth.signOut();
        setIsLoggedIn(false);
    };

    return (
        <Layout className={styles.screen}>
            <HeaderComponent
                onLogin={onLogin}
                onLogoClick={onLogoClick}
                bottomSeparator={bottomSeperator}
                logoSize={logoSize}
                isLoggedIn={isLoggedIn}
                onSignOut={onSignOut}
            />
            <Banner
                onBannerClose={onBannerClose}
                showBanner={showBanner}
                setShowBanner={setShowBanner}
            />
            <Content className={styles.mainContentWrapper}>
                <div className={styles.mainContent}>{children}</div>
            </Content>
            {hasFooter && (
                <Footer className={styles.footer}>
                    <Link href="/privacy.html">{Config.homeScreen.footerPrivacyPolicyText}</Link>
                    <Divider type="vertical" />
                    <Link
                        href={'mailto:' + Config.homeScreen.footerContactUsMailAddress}
                        onClick={handleContactUsClicked}
                    >
                        {Config.homeScreen.footerContactUsText}
                    </Link>
                </Footer>
            )}
        </Layout>
    );
};
export default Screen;
