import React from 'react';
import '../styles/styles.css';

import { isMobile } from '../utils/Utils';

import ScreenTransition from './ScreenTransition/ScreenTransition';
import ThemeProvider from '../providers/ThemeProvider/ThemeProvider';
import ContextProvider from '../providers/ContextProvider/ContextProvider';
import UnsupportedDevice from './UnsupportedDevice';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Auth from '../auth/Auth';

const App: React.FC = () => {
    if (isMobile()) {
        return <UnsupportedDevice />;
    }

    return (
        <ThemeProvider>
            <ContextProvider>
                <GoogleOAuthProvider clientId={Auth.googleClientId}>
                    <ScreenTransition />
                </GoogleOAuthProvider>
            </ContextProvider>
        </ThemeProvider>
    );
};

export default App;
