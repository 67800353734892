import React, { createContext, useContext, useEffect, useState } from 'react';
import Auth from '../../auth/Auth';
import URLParams, { URLHandler } from '../../core/URLHandler';

interface ContextProviderProps {
    currentScreen: ScreenName;
    setCurrentScreen: React.Dispatch<React.SetStateAction<ScreenName>>;
}

enum ScreenName {
    UNDEFINED = '',
    HOME = 'home',
    SPLIT = 'split',
}

const AppContext = createContext<ContextProviderProps | undefined>(undefined);

const ContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [currentScreen, setCurrentScreen] = useState<ScreenName>(ScreenName.UNDEFINED);

    useEffect(() => {
        const handleRefresh = () => {
            Auth.sessionId(true);
            URLParams.clear();
        };

        /* Decide on the first screen to show. */
        setCurrentScreen(URLHandler.firstScreenConfig.screenName);

        // Optionally, listen for actual page refreshes
        window.addEventListener('beforeunload', handleRefresh);

        return () => {
            window.removeEventListener('beforeunload', handleRefresh);
        };
    }, []);

    return (
        <AppContext.Provider value={{ currentScreen, setCurrentScreen }}>
            {children}
        </AppContext.Provider>
    );
};

export function useAppContext(): ContextProviderProps {
    const context = useContext(AppContext);

    if (!context) {
        throw new Error('useAppContext must be used within a ScreenProvider');
    }
    return context;
}

export { ScreenName };
export default ContextProvider;
