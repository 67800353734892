import { v4 as uuidv4 } from 'uuid';
import { AVATAR_STORAGE_KEY, NOT_AVAILABLE } from '../config/constants';
import Mixpanel from '../analytics/Mixpanel';
import NameGenerator from './NameGenerator';
import localforage from 'localforage';
import { AnalyticsEvent, AnalyticsEventName } from '../analytics/AnalyticsEvent';
import Logger from '../logging/logger';
import Contentful from '../content_mangment/contentful';
import URLParams from '../core/URLHandler';

enum Variant {
    A = 'A',
    B = 'B',
    C = 'C',
}

export interface UserDetails {
    id: string;
    name: string;
    pictureUrl: string;
    pictureLoaded: boolean;
    $email: string;
    provider: SSOProvider | undefined;
    variant: Variant;
}

export enum SSOProvider {
    GOOGLE = 'google',
    GOOGLE_ONE_TAP = 'google_one_tap',
    LINKEDIN = 'linkedin',
    FACEBOOK = 'facebook',
}

export interface GoogleUser {
    email: string;
    family_name: string;
    given_name: string;
    name: string;
    pictureUrl: string;
    $email: string;
    provider: SSOProvider | undefined;
}

const USER_EMPTY: UserDetails = {
    id: '',
    name: '',
    pictureUrl: '',
    pictureLoaded: false,
    $email: '',
    provider: undefined,
    variant: Variant.A,
};

class Auth {
    public static googleClientId: string;
    public static linkedInClientId: string;
    public static facebookAppId: string;

    public static loggedIn: boolean = false;
    private static fedcmEnabled: boolean = false;
    private static user: UserDetails = USER_EMPTY;
    private static currentBlobUrl: string | null = null;
    private static userToken: string = '';

    public static init(googleClientId: string, linkedInClientId: string, facebookAppId: string) {
        Auth.googleClientId = googleClientId;
        Auth.linkedInClientId = linkedInClientId;
        Auth.facebookAppId = facebookAppId;
        Auth.loadUserToken();

        /* Cleanup blob url on exit. */
        window.addEventListener('unload', () => {
            Auth.cleanupBlobUrl();
        });
    }

    private static generateUserId(): string {
        return uuidv4();
    }

    public static async loadUserFromLocalStorage(): Promise<void> {
        const userString = localStorage.getItem('user-string');

        Auth.user = {
            id: Auth.generateUserId(),
            name: NameGenerator.generate(),
            pictureUrl: '',
            pictureLoaded: false,
            $email: '',
            provider: undefined,
            variant: Auth.calcVariant(Contentful.getConfigNum()),
        };

        if (userString) {
            const tempUser = JSON.parse(userString) as UserDetails;

            (Object.keys(Auth.user) as Array<keyof UserDetails>).forEach((key) => {
                if (key in tempUser) {
                    (Auth.user[key] as any) = tempUser[key]!;
                }
            });

            Auth.fetchAndCacheImage(AVATAR_STORAGE_KEY, Auth.user.pictureUrl).then((avatar) => {
                Auth.user.pictureLoaded = !!avatar;
            });
        }

        localStorage.setItem('user-string', JSON.stringify(Auth.user));

        const userLoggedIn = localStorage.getItem('user-logged-in');
        if (userLoggedIn == null) {
            Auth.setLoggedIn(false);
        } else {
            Auth.setLoggedIn(userLoggedIn === 'true');
        }
    }

    private static delay = (ms: number): Promise<void> => {
        return new Promise((resolve) => setTimeout(resolve, ms));
    };

    public static async getAvatarPictureURL(): Promise<string> {
        let retries = 10;
        while (!Auth.user.pictureLoaded && retries > 0) {
            await Auth.delay(100);
            retries--;
        }
        if (!Auth.user.pictureUrl) {
            return '';
        }
        let cached = await localforage.getItem<Blob>(AVATAR_STORAGE_KEY);
        if (cached instanceof Blob && cached.type.startsWith('image')) {
            return URL.createObjectURL(cached);
        }
        return '';
    }

    public static sessionId(forceNew: boolean = false): string {
        let sessionId = sessionStorage.getItem('sessionId');
        if (!sessionId || forceNew) {
            sessionId = uuidv4(); // Generate a new UUID
            sessionStorage.setItem('sessionId', sessionId);
            Mixpanel.register(Mixpanel.MixpanelPropertyName.SESSION_ID, sessionId);
        }
        return sessionId;
    }

    public static loadUserToken() {
        const getUrlToken = () => {
            return (
                URLParams.getUserToken() ||
                process.env.REACT_APP_USER_TAG ||
                localStorage.getItem('token') ||
                NOT_AVAILABLE
            );
        };

        const urlToken = getUrlToken();
        localStorage.setItem('token', urlToken);
        Auth.userToken = urlToken;
    }

    public static getUserToken(): string {
        return Auth.userToken;
    }

    public static logIn(provider: SSOProvider, response: any) {
        // Send event first so the last event of the annon user in mixpanel will be the login event
        AnalyticsEvent.create(AnalyticsEventName.SIGN_UP_SUCCESS)
            .set('type', provider.toLowerCase())
            .mixpanelTrack()
            .googleAnalyticsTrack();

        switch (provider) {
            case SSOProvider.GOOGLE:
            case SSOProvider.GOOGLE_ONE_TAP:
                Auth.logInWithGoogle(response);
                break;
            default:
                Logger.error('Unknown provider: ', provider);
        }
    }

    private static logInWithGoogle = (response: any) => {
        let id: string;
        let variant: Variant;

        if (Auth.getUser().id) {
            id = Auth.getUser().id;
            variant = Auth.getUser().variant;
        } else {
            /* This branch should not be reached, as even if the user is not loaded from local storage,
             we generate him an id and a variant. */
            variant = Auth.calcVariant(Contentful.getConfigNum());
        }

        Auth.fetchAndCacheImage(AVATAR_STORAGE_KEY, response.picture).then((avatar) => {
            Auth.commonLogIn({
                id: id,
                name: response.name,
                pictureUrl: response.picture,
                pictureLoaded: !!avatar,
                $email: response.email,
                provider: SSOProvider.GOOGLE,
                variant: variant,
            });
        });
    };

    private static commonLogIn(user: UserDetails) {
        Mixpanel.handleLogIn(user.$email, user);

        Auth.user = user;
        localStorage.setItem('user-string', JSON.stringify(user));
        Auth.setLoggedIn(true);
    }

    private static setLoggedIn(loggedIn: boolean) {
        Auth.loggedIn = loggedIn;
        localStorage.setItem('user-logged-in', loggedIn.toString());
    }

    public static handleSSOFailure(provider: SSOProvider, error: any) {
        AnalyticsEvent.create(AnalyticsEventName.SSO_FAILED)
            .set('sso', provider.toLowerCase())
            .mixpanelTrack();
        Logger.error('Error in SSO login: ', provider, error);
    }

    public static signOut() {
        Auth.setLoggedIn(false);
        Auth.cleanupBlobUrl();
        void localforage.removeItem(AVATAR_STORAGE_KEY);
        Auth.user = USER_EMPTY;
    }

    public static isFedcmEnabled(): boolean {
        let fedcmEnabled: boolean = false;

        /* Check if the browser supports FedCM. */
        const supported = 'IdentityCredential' in window;
        if (!supported) {
            Auth.fedcmEnabled = false;
        } else {
            // Try to determine if FedCM is enabled
            (window as any).navigator?.credentials
                ?.get?.({ identity: { providers: [] } })
                .then((_: any) => {
                    fedcmEnabled = true;
                })
                .catch((error: any) => {
                    Auth.fedcmEnabled = !error.message?.includes('disabled');
                });
        }
        Auth.fedcmEnabled = fedcmEnabled;
        return Auth.fedcmEnabled;
    }

    public static getUser(): UserDetails {
        return Auth.user;
    }

    public static setUserId(id: string) {
        Auth.user.id = id;
    }

    private static async fetchAndCacheImage(key: string, url: string = ''): Promise<Blob | null> {
        try {
            Auth.cleanupBlobUrl();

            let cached = await localforage.getItem<Blob>(key);
            if (cached instanceof Blob && !cached.type.startsWith('image')) {
                /* Error in cache, force re-fetch. */
                cached = null;
                await localforage.removeItem(key);
            }
            if (!cached) {
                if (!url) {
                    return null;
                }

                const blob = await fetch(url, {
                    mode: 'cors',
                    credentials: 'omit',
                    headers: {
                        Accept: 'image/*',
                    },
                }).then((r) => r.blob());
                await localforage.setItem(key, blob);
                cached = blob;
            }

            return cached;
        } catch (e) {
            Logger.error('Error caching image', e);
            return null;
        }
    }

    private static cleanupBlobUrl() {
        if (Auth.currentBlobUrl?.startsWith('blob:')) {
            URL.revokeObjectURL(Auth.currentBlobUrl);
            Auth.currentBlobUrl = null;
        }
    }

    private static calcVariant(numOfConfigs: number): Variant {
        if (numOfConfigs === 0) {
            return Variant.A;
        }

        const numberValue = parseInt(Math.random().toString(16).slice(2, 10), 16);
        return Object.values(Variant)[numberValue % numOfConfigs] as Variant;
    }
}

export { Variant };
export default Auth;
