import React from 'react';
import { ConfigProvider } from 'antd';

const primary = '#2b308b';
const white = '#ffffff';

const theme = {
    components: {
        Layout: {
            footerBg: 'transparent',
        },
        Button: {
            colorPrimary: primary,
            primaryColor: white,
            borderColorDisabled: 'transparent',
        },
        Table: {
            headerBg: 'transparent',
            headerBorderRadius: 20,
        },
    },
    token: {
        colorTextPlaceholder: '#808080',
        colorLink: primary,
    },
};

const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <ConfigProvider theme={theme}>{children}</ConfigProvider>
);

export default ThemeProvider;
