import { Button, Flex, Progress, Table, Tooltip } from 'antd';
import SymbolList from './SymbolList';
import { CheckCircleFilled, CloseCircleFilled, DollarOutlined } from '@ant-design/icons';
import React, { ReactNode } from 'react';
import styles from '../../styles/screen/SplitScreen/Table/Table.module.css';
import LoaderAnimation from '../LoaderAnimation/LoaderAnimation';
import { ToolData } from '../../core/Core';
import { renderURL } from '../../utils/Utils';
import Logo from '../Logo';
import { AnalyticsEvent, AnalyticsEventName } from '../../analytics/AnalyticsEvent';
import { ColumnType } from 'antd/es/table';
import { TableConfig } from './table.types';

const renderFeatureCell = (feature: string) => {
    return <div className={styles.tableColumnText}>{feature}</div>;
};
const renderTableState = (isLoading: boolean) => (
    <Flex
        align={'center'}
        justify={'center'}
        style={{
            width: '85px',
            height: '85px',
        }}
    >
        {isLoading ? (
            <LoaderAnimation fileName={'/animations/loading_graph.json'} withTitle={false} />
        ) : null}
    </Flex>
);

const renderTitle = (index: number, tool: ToolData, logoLinkText: string) => (
    <Flex vertical justify={'center'} align={'center'}>
        <Flex
            vertical
            justify={'center'}
            align={'center'}
            className={styles.tableLogoRow}
            gap={'11px'}
        >
            {/* <span className="text-sm">{tool.name}</span> */}
            <a
                href={renderURL(tool.domain)}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => onLinkToVendorButtonClick(index, tool)}
                className="cursor-pointer font-medium h-full flex items-center flex-col gap-2"
            >
                <Logo src={tool.logo} alt={tool.name} href={tool.domain} width={128} />
            </a>
            {renderDomain(index, tool, logoLinkText)}
        </Flex>
    </Flex>
);
const renderDomain = (index: number, tool: ToolData, logoLinkText: string) => {
    const href =
        tool.domain.startsWith('http://') || tool.domain.startsWith('https://')
            ? tool.domain
            : `https://${tool.domain}`;
    return (
        <a
            href={href}
            className="cursor-pointer font-medium"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => onLinkToVendorButtonClick(index, tool)}
            style={{ color: '#2B308B' }}
        >
            {logoLinkText}
        </a>
    );
};
const onLinkToVendorButtonClick = (index: number, tool: ToolData) => {
    AnalyticsEvent.create(AnalyticsEventName.BUTTON_CLICKED_VENDOR_LINK)
        .set('software_name', tool.name)
        .set('url', tool.domain)
        .set('column_number', index)
        .mixpanelTrack()
        .googleAnalyticsTrack();
};
const columns = (
    tools: ToolData[],
    isLoading: boolean,
    config: TableConfig
): ColumnType<DataType>[] => [
    {
        title: renderTableState(isLoading),
        dataIndex: 'feature',
        key: 'feature',
        width: 150,
        fixed: 'left' as const,
    },
    ...tools.map((tool, index) => ({
        title: () => renderTitle(index, tool, config.logoLinkText),
        dataIndex: tool.name,
        key: tool.name,
        align: 'center' as const,
        width: 200,
        cellPadding: 0,
    })),
];
const renderMatchScore = (value: number) => {
    const color = value > 7 ? '#52c41a' : value > 5 ? '#faad14' : '#f5222d';
    return (
        <Progress
            type="circle"
            percent={value * 10}
            format={() => `${value}`}
            size={50}
            strokeColor={color}
        />
    );
};

const renderPricing = (value: number) => (
    <span className="text-lg">
        {[...Array(value)].map((_, i) => (
            <DollarOutlined key={`dollar-${value}-${i}`} className="text-green-600" />
        ))}
    </span>
);

const styleCell = (content: any) => {
    return <div className={styles.tableCellText}>{content}</div>;
};
export type DataType = {
    rowKey: string;
    [key: string]: React.ReactNode;
    feature: React.ReactNode;
};

const data = (tools: ToolData[], config: TableConfig): DataType[] => [
    {
        rowKey: config.columns[0].key,
        feature: renderFeatureCell(config.columns[0].displayName),
        ...Object.fromEntries(tools.map((tool) => [tool.name, renderMatchScore(tool.matchScore)])),
    },
    {
        rowKey: config.columns[1].key,
        feature: renderFeatureCell(config.columns[1].displayName),
        ...Object.fromEntries(tools.map((tool) => [tool.name, styleCell(tool.summary)])),
    },
    {
        rowKey: config.columns[2].key,
        feature: renderFeatureCell(config.columns[2].displayName),
        ...Object.fromEntries(tools.map((tool) => [tool.name, renderPricing(tool.pricing)])),
    },
    {
        rowKey: config.columns[3].key,
        feature: renderFeatureCell(config.columns[3].displayName),
        ...Object.fromEntries(tools.map((tool) => [tool.name, styleCell(tool.organizationSize)])),
    },
    {
        rowKey: config.columns[4].key,
        feature: renderFeatureCell(config.columns[4].displayName),
        ...Object.fromEntries(
            tools.map((tool) => [
                tool.name,
                <Tooltip
                    key={`love-${tool.name}`}
                    title={tool.love}
                    className="flex justify-start content-start"
                >
                    <SymbolList
                        className={styles.tableCellText}
                        items={tool.love.split(', ')}
                        symbol={<CheckCircleFilled className="text-green-600" />}
                    />
                </Tooltip>,
            ])
        ),
    },
    {
        rowKey: config.columns[5].key,
        feature: renderFeatureCell(config.columns[5].displayName),
        ...Object.fromEntries(
            tools.map((tool) => [
                tool.name,
                <Tooltip key={`hate-${tool.name}`} title={tool.hate}>
                    <SymbolList
                        className={styles.tableCellText}
                        items={tool.hate.split(', ')}
                        symbol={<CloseCircleFilled className="text-red-600" />}
                    />
                </Tooltip>,
            ])
        ),
    },
    {
        rowKey: config.columns[6].key,
        feature: renderFeatureCell(config.columns[6].displayName),
        ...Object.fromEntries(tools.map((tool) => [tool.name, styleCell(tool.supportLevel)])),
    },
    {
        rowKey: config.columns[7].key,
        feature: renderFeatureCell(config.columns[7].displayName),
        ...Object.fromEntries(tools.map((tool) => [tool.name, styleCell(tool.easeOfIntegration)])),
    },
];

const tableSummary = (tools: ToolData[], config: TableConfig): ReactNode => (
    <Table.Summary fixed>
        <Table.Summary.Row>
            <Table.Summary.Cell index={0} key="summary-feature" />
            {tools.map((tool, index) => (
                <Table.Summary.Cell index={index} key={`summary-${tool.name}`}>
                    <Flex align={'center'} justify={'center'}>
                        <Button
                            key={`link-to-vendor-${index}`}
                            type="primary"
                            shape={'round'}
                            href={renderURL(tool.domain)}
                            target="_blank"
                            onClick={(e) => {
                                e.preventDefault();
                                onLinkToVendorButtonClick(index, tool);
                                window.open(renderURL(tool.domain), '_blank');
                            }}
                        >
                            {config.logoLinkText}
                        </Button>
                    </Flex>
                </Table.Summary.Cell>
            ))}
        </Table.Summary.Row>
    </Table.Summary>
);
export { columns, data, onLinkToVendorButtonClick, tableSummary };
