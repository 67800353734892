import React, { useState, useEffect } from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { Typography } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import Config from '../../config/config';

const { Title } = Typography;

export enum LoadingScriptMode {
    rotating = 'rotating',
    lastSticky = 'lastSticky',
}

interface LoaderAnimationProps {
    fileName: string;
    width?: string | number;
    height?: string | number;
    withTitle?: boolean;
}

const LoaderAnimation: React.FC<LoaderAnimationProps> = ({
    fileName,
    width,
    height,
    withTitle = true,
}) => {
    const mode = Config.loaderAnimation.mode;
    const script = Config.loaderAnimation.script;
    const transitionDuration = Config.loaderAnimation.transitionDuration;

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);

    useEffect(() => {
        const nextItem = () => {
            if (currentIndex < script.length - 1 || mode === LoadingScriptMode.rotating) {
                setIsTransitioning(true);
                setTimeout(() => {
                    setCurrentIndex((prevIndex) =>
                        mode === LoadingScriptMode.rotating && prevIndex === script.length - 1
                            ? 0
                            : prevIndex + 1
                    );
                    setIsTransitioning(false);
                }, transitionDuration);
            }
        };

        const timer = setTimeout(nextItem, script[currentIndex].duration);

        return () => clearTimeout(timer);
    }, [currentIndex, script, mode, transitionDuration]);

    return (
        <div className="flex items-center justify-center" style={{ width, height }}>
            <div className="flex flex-col items-center">
                <DotLottieReact src={fileName} loop autoplay />
                {withTitle && (
                    <AnimatePresence mode="wait">
                        <motion.div
                            key={currentIndex}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{
                                opacity: isTransitioning ? 0 : 1,
                                y: isTransitioning ? 20 : 0,
                            }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: transitionDuration / 1000 }}
                        >
                            <Title level={1}>{script[currentIndex].text}</Title>
                        </motion.div>
                    </AnimatePresence>
                )}
            </div>
        </div>
    );
};

export default LoaderAnimation;
