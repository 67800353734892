import Hotjar from '@hotjar/browser';
import { HOTJAR_VERSION } from '../config/constants';
import Config from '../config/config';
import Auth from '../auth/Auth';

class HotjarAnalytics {
    static init(siteId: number) {
        if (!Config.isDevMode) {
            void new Promise(() => Hotjar.init(siteId, HOTJAR_VERSION));
        }
    }

    static identify() {
        if (!Config.isDevMode) {
            Hotjar.identify(Auth.getUser().id, {});
        }
    }
}

export default HotjarAnalytics;
