import React from 'react';
import { Button } from 'antd';

interface CircleButtonProps {
    buttonSize?: any;
    iconSize?: string | number;
    bgColor?: string;
    iconColor?: string;
    onClick?: () => void;
    disabled?: boolean;
}

const CircleButton: React.FC<CircleButtonProps> = ({
    buttonSize = 'small',
    iconSize = '25px',
    iconColor = '#fff',
    onClick,
    disabled = false,
}) => {
    return (
        <Button
            shape="circle"
            type="primary"
            size={buttonSize}
            icon={
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={iconSize}
                    height={iconSize}
                    viewBox="0 0 20 20"
                    fill="none"
                >
                    <path
                        d="M6 9.5C5.72386 9.5 5.5 9.72386 5.5 10C5.5 10.2761 5.72386 10.5 6 10.5V9.5ZM13.3536 10.3536C13.5488 10.1583 13.5488 9.84171 13.3536 9.64645L10.1716 6.46447C9.97631 6.2692 9.65973 6.2692 9.46447 6.46447C9.2692 6.65973 9.2692 6.97631 9.46447 7.17157L12.2929 10L9.46447 12.8284C9.2692 13.0237 9.2692 13.3403 9.46447 13.5355C9.65973 13.7308 9.97631 13.7308 10.1716 13.5355L13.3536 10.3536ZM6 10.5L13 10.5V9.5L6 9.5V10.5Z"
                        fill={iconColor}
                    />
                </svg>
            }
            onClick={onClick}
            disabled={disabled}
            style={disabled ? { background: '#808080' } : {}}
        ></Button>
    );
};

export default CircleButton;
