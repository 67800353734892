import React, { useState } from 'react';
import styles from '../../styles/screen/HomeScreen/SearchBox.module.css';
import { Flex, Input } from 'antd';
import CircleButton from '../CircleButton';
import { runes } from 'runes2';
import Typer from '../Typer/Typer';

interface SearchBoxProperties {
    hints?: string[];
    hintsRotateInterval?: number /* msec */;
    rows?: number;
    onSearch: (input: string) => void;
    hasFileUploader?: boolean;
    maxChars: number;
}

const { TextArea } = Input;

const SearchBox: React.FC<SearchBoxProperties> = ({
    hints = [],
    hintsRotateInterval = 1000,
    rows = 3,
    onSearch,
    hasFileUploader = false,
    maxChars = 1000,
}) => {
    const [inputValue, setInputValue] = useState<string>('');

    return (
        <div className={styles.searchBox}>
            <Flex vertical justify={'space-between'} flex="1 1">
                <TextArea
                    variant="borderless"
                    value={inputValue}
                    placeholder=""
                    autoSize={{ minRows: rows, maxRows: rows }}
                    count={{
                        max: maxChars,
                        exceedFormatter: (txt, { max }) => runes(txt).slice(0, max).join(''),
                    }}
                    className={styles.searchBoxPlaceholder}
                    onChange={(e) => setInputValue(e.target.value)}
                    onPressEnter={(e) => {
                        if (!e.shiftKey) {
                            e.preventDefault();
                            if (inputValue.trim()) {
                                onSearch(inputValue);
                            }
                        }
                    }}
                    data-hj-allow
                />
                {!inputValue && hints.length > 0 && (
                    <div className={styles.placeholderOverlay}>
                        <Typer texts={hints} delayBetweenTexts={hintsRotateInterval} />
                    </div>
                )}
                <Flex justify="end">
                    <CircleButton
                        buttonSize="medium"
                        iconSize="40px"
                        onClick={() => onSearch(inputValue)}
                        disabled={!inputValue.trim()}
                    />
                </Flex>
            </Flex>
        </div>
    );
};

export default SearchBox;
