import React, { forwardRef, ReactElement } from 'react';
import { List } from 'antd';

interface SymbolListProps {
    items: any[];
    symbol: ReactElement;
    className?: string;
}

const SymbolList: React.FC<SymbolListProps> = forwardRef<HTMLDivElement, SymbolListProps>(
    ({ items, symbol, className = '' }, ref) => {
        return (
            <List
                ref={ref}
                className={className}
                dataSource={items}
                renderItem={(item) => (
                    <List.Item className="p-0">
                        <div className="w-full flex">
                            <div className="pr-2">{React.cloneElement(symbol)}</div>
                            <div className="text-left">{item}</div>
                        </div>
                    </List.Item>
                )}
            />
        );
    }
);

SymbolList.displayName = 'SymbolList';

export default SymbolList;
