import React, { useEffect } from 'react';
import { Button, Card, Divider, Flex, Modal, Typography, Image } from 'antd';
import Config from '../../config/config';
import { SearchOutlined } from '@ant-design/icons';
import ContentfulRichText from '../ContentfulRichText/ContentfulRichText';
import styles from './LandingPopup.module.scss';
import { LANDING_PROP_PLACEHOLDER_STRING } from '../../config/constants';
import { AnalyticsEvent, AnalyticsEventName } from '../../analytics/AnalyticsEvent';

const { Title, Text } = Typography;

interface LandingPopupProps {
    visible: boolean;
    onClose?: () => void;
    term: string;
}

const LandingPopup: React.FC<LandingPopupProps> = ({ visible, onClose = () => {}, term }) => {
    useEffect(() => {
        if (visible) {
            AnalyticsEvent.create(AnalyticsEventName.LANDING_POPUP_SHOWN).mixpanelTrack();
        }
    }, []);

    const handleShowMyButtonClick = () => {
        AnalyticsEvent.create(AnalyticsEventName.LANDING_POPUP_SHOW_MY_BUTTON_CLICKED)
            .set('term', term)
            .mixpanelTrack();

        onClose();
    };

    const handlePopupClose = () => {
        AnalyticsEvent.create(AnalyticsEventName.LANDING_POPUP_CLOSED).mixpanelTrack();
        onClose();
    };

    const handleDifferentButtonClick = () => {
        AnalyticsEvent.create(
            AnalyticsEventName.LANDING_POPUP_SHOW_DIFFERENT_BUTTON_CLOSED
        ).mixpanelTrack();
        onClose();
        window.location.reload();
    };

    return (
        <Modal
            open={visible}
            onClose={handlePopupClose}
            onCancel={handlePopupClose}
            title={null}
            footer={null}
            width={styles.popupWidth}
            className={styles.modal}
            centered={true}
            style={{ top: 0 }}
        >
            <Card bordered={false} className={styles.outerCard}>
                <Flex vertical align={'center'} justify={'center'} gap={30}>
                    <Flex vertical align={'center'} justify={'center'} gap={10}>
                        <Title level={2} className={styles.headline}>
                            {Config.landingPopup.title}
                        </Title>
                        <Text className={styles.text}>{Config.landingPopup.subtitle}</Text>
                    </Flex>
                    <Card bordered={false} className={styles.innerCard}>
                        <ContentfulRichText
                            content={Config.landingPopup.text}
                            spaceSize={3}
                            listItemSpacing={5}
                        />
                    </Card>
                    <Flex
                        vertical
                        align={'center'}
                        justify={'center'}
                        gap={2}
                        className={styles.container}
                    >
                        <Button
                            type={'primary'}
                            icon={
                                <Image src={'/icons/building-2.svg'} alt={'go'} preview={false} />
                            }
                            className={styles.button}
                            onClick={handleShowMyButtonClick}
                        >
                            {Config.landingPopup.upperButtonText.replace(
                                LANDING_PROP_PLACEHOLDER_STRING,
                                term
                            )}
                        </Button>
                        <Divider>
                            <span className={styles.separatorText}>
                                {Config.landingPopup.separatorText}
                            </span>
                        </Divider>
                        <Button
                            icon={<SearchOutlined />}
                            className={styles.button}
                            onClick={handleDifferentButtonClick}
                        >
                            {Config.landingPopup.lowerButtonText}
                        </Button>
                    </Flex>
                </Flex>
            </Card>
        </Modal>
    );
};

export default LandingPopup;
