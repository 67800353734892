import React from 'react';
import { Button, Card, Space, Typography } from 'antd';
import styles from '../../../styles/components/screens/SignOut.module.css';
import { LogoutOutlined } from '@ant-design/icons';
import Config from '../../../config/config';
import { AnalyticsEvent, AnalyticsEventName } from '../../../analytics/AnalyticsEvent';

const { Text } = Typography;

interface SignOutProps {
    onSignOut: () => void;
    userName: string;
}

const SignOut: React.FC<SignOutProps> = ({ onSignOut, userName }) => {
    const handleSignOutButtonClick = () => {
        AnalyticsEvent.create(AnalyticsEventName.SIGN_OUT_BUTTON_CLICKED).mixpanelTrack();
        onSignOut();
    };

    return (
        <Card bordered={false} className={styles.signOutCard}>
            <Space direction="vertical" size="middle" className={styles.signOutContainer}>
                <Text className={styles.greeting}>
                    {Config.signOut.greeting} <Text strong>{userName}</Text>
                </Text>

                <Button
                    type="primary"
                    icon={<LogoutOutlined />}
                    size="middle"
                    block
                    onClick={handleSignOutButtonClick}
                    className={styles.signOutButton}
                >
                    {Config.signOut.buttonText}
                </Button>
            </Space>
        </Card>
    );
};

export default SignOut;
