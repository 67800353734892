import React, { useEffect } from 'react';
import { Typography, Row, Col, Flex } from 'antd';
import '../../../styles/styles.css';
import Screen from '../Screen/Screen';
import { AnalyticsEventName, AnalyticsEvent } from '../../../analytics/AnalyticsEvent';
import ClickableCard from '../../ClickableCard';
import SearchBox from '../../SearchBox/SearchBox';
import Config from '../../../config/config';
import styles from './HomeScreen.module.scss';

const { Title, Paragraph, Text } = Typography;

export interface HomeScreenProps {
    onPromptSend(prompt: string): void;
}

const HomeScreen: React.FC<HomeScreenProps> = ({ onPromptSend }) => {
    useEffect(() => {
        AnalyticsEvent.create(AnalyticsEventName.PAGE_LOADED_HOME)
            .mixpanelTrack()
            .googleAnalyticsTrack();
    }, []);

    const handleButtonClick = (index: number, prompt: string) => {
        AnalyticsEvent.create(AnalyticsEventName.BUTTON_CLICKED_JOURNEY_SUGGESTION)
            .set('location', index)
            .set('text', prompt)
            .mixpanelTrack()
            .googleAnalyticsTrack();
        onPromptSend(prompt);
    };

    const handleInputSearch = (prompt: string) => {
        AnalyticsEvent.create(AnalyticsEventName.BUTTON_CLICKED_SEARCH)
            .set('text', prompt)
            .mixpanelTrack();
        onPromptSend(prompt);
    };

    return (
        <Screen
            bottomSeperator={false}
            showBanner={false}
            setShowBanner={(_) => {}}
            hasFooter={true}
        >
            <div className={styles.contentContainer}>
                <Flex className={styles.flexContainer}>
                    <Flex vertical gap={'10px'}>
                        <Title level={2} className={styles.headline}>
                            {Config.homeScreen.title}
                        </Title>
                        <Paragraph className={styles.paragraph} style={{ marginBottom: 0 }}>
                            <Text className={styles.text}>{Config.homeScreen.subtitle1}</Text>
                            <Text className={styles.textBolded}>{Config.homeScreen.subtitle2}</Text>
                            <br />
                            <Text className={styles.text}>{Config.homeScreen.subtitle3}</Text>
                        </Paragraph>
                    </Flex>
                    <SearchBox
                        hints={Config.homeScreen.searchBoxHints}
                        rows={3}
                        hasFileUploader={false}
                        onSearch={handleInputSearch}
                        maxChars={Config.homeScreen.searchBoxMaxChars}
                        hintsRotateInterval={Config.homeScreen.searchBoxDelayMsec}
                    />
                    {Config.appConfiguration?.showJourneySuggestions && (
                        <Row gutter={[16, 16]}>
                            {Config.homeScreen.journeySuggestions.map(
                                (query: any, index: number) => (
                                    <Col xs={24} sm={12} md={8} key={index}>
                                        <ClickableCard
                                            title={query.mainTitle}
                                            subtitle={query.subTitle}
                                            onClick={() =>
                                                handleButtonClick(index, query.mainTitle)
                                            }
                                        />
                                    </Col>
                                )
                            )}
                        </Row>
                    )}
                </Flex>
            </div>
        </Screen>
    );
};

export default HomeScreen;
