import Contentful from '../content_mangment/contentful';
import { HomeScreenConfig } from '../components/screens/HomeScreen/HomeScreen.types';
import { BannerConfig } from '../components/screens/Screen/Banner/Banner.types';
import { LeaveEmailBoxConfig } from '../components/screens/Screen/LeaveEmailBox/LeaveEmailBox.types';
import { TableConfig } from '../components/Table/table.types';
import { EmptyDataConfig } from '../components/EmptyData/EmptyData.types';
import { CONTENTFUL_CONFIG } from './constants';
import { ChatConfig } from '../components/Chat/Chat.types';
import { LoaderAnimationConfig } from '../components/LoaderAnimation/LoaderAnimation.types';
import { SignUpConfig } from '../components/screens/SignUp/SignUp.types';
import { SignOutConfig } from '../components/screens/SignOut/SignOut.types';
import Logger from '../logging/logger';
import { AppConfiguration } from './configuration.types';
import Auth, { Variant } from '../auth/Auth';
import { UTMWhiteList } from '../components/ScreenTransition/ScreenTransition.types';
import { LandingPopupConfig } from '../components/LandingPopup/LandingPopup.types';

export default class Config {
    static isDevMode: boolean = process.env.REACT_APP_DEV_MODE === 'true';
    static appConfiguration?: AppConfiguration;
    static banner: BannerConfig;
    static homeScreen: HomeScreenConfig;
    static leaveEmailBox: LeaveEmailBoxConfig;
    static table: TableConfig;
    static emptyData: EmptyDataConfig;
    static chat: ChatConfig;
    static loaderAnimation: LoaderAnimationConfig;
    static signUp: SignUpConfig;
    static signOut: SignOutConfig;
    static utmWhiteList: UTMWhiteList;
    static landingPopup: LandingPopupConfig;

    static async init() {
        async function loadConfig() {
            try {
                [
                    Config.banner,
                    Config.leaveEmailBox,
                    Config.table,
                    Config.emptyData,
                    Config.chat,
                    Config.loaderAnimation,
                    Config.homeScreen,
                    Config.signUp,
                    Config.signOut,
                    Config.utmWhiteList,
                    Config.landingPopup,
                ] = await Promise.all([
                    Contentful.fetchEntry(
                        CONTENTFUL_CONFIG.ENTRY_IDS.BANNER_CONFIG
                    ) as unknown as BannerConfig,
                    Contentful.fetchEntry(
                        CONTENTFUL_CONFIG.ENTRY_IDS.LEAVE_EMAIL_BOX_CONFIG
                    ) as unknown as LeaveEmailBoxConfig,
                    Contentful.fetchEntry(
                        CONTENTFUL_CONFIG.ENTRY_IDS.TABLE_CONFIG
                    ) as unknown as TableConfig,
                    Contentful.fetchEntry(
                        CONTENTFUL_CONFIG.ENTRY_IDS.EMPTY_DATA_CONFIG
                    ) as unknown as EmptyDataConfig,
                    Contentful.fetchEntry(
                        CONTENTFUL_CONFIG.ENTRY_IDS.CHAT_CONFIG
                    ) as unknown as ChatConfig,
                    Contentful.fetchEntry(
                        CONTENTFUL_CONFIG.ENTRY_IDS.LOADER_ANIMATION_CONFIG
                    ) as unknown as LoaderAnimationConfig,
                    Contentful.fetchEntry(
                        CONTENTFUL_CONFIG.ENTRY_IDS.MAIN_SCREEN
                    ) as unknown as HomeScreenConfig,
                    Contentful.fetchEntry(
                        CONTENTFUL_CONFIG.ENTRY_IDS.SIGNUP_CONFIG
                    ) as unknown as SignUpConfig,
                    Contentful.fetchEntry(
                        CONTENTFUL_CONFIG.ENTRY_IDS.SIGNOUT_CONFIG
                    ) as unknown as SignOutConfig,
                    Contentful.fetchEntry(
                        CONTENTFUL_CONFIG.ENTRY_IDS.WHITELIST_ID
                    ) as unknown as UTMWhiteList,
                    Contentful.fetchEntry(
                        CONTENTFUL_CONFIG.ENTRY_IDS.LANDING_POPUP_CONFIG
                    ) as unknown as LandingPopupConfig,
                ]);
            } catch (e) {
                console.debug('Error fetching config: ', e);
                Logger.error('Error fetching config: ', e);
                throw new Error('Error fetching config');
            }
        }
        if (Contentful.getConfigNum()) {
            await loadConfig();
        }
    }

    public static setUserDependentConfig() {
        if (Contentful.configurations) {
            Config.appConfiguration =
                Contentful.configurations[Object.values(Variant).indexOf(Auth.getUser().variant)];
        }
    }
}
