export enum MessageType {
    INVALID = 'invalid',
    INVALID_USER_INPUT = 'invalid_user_input',
    LOADING = 'loading',
    TOOLS_FOUND = 'tools_found',
    OPEN = 'open',
    INFO = 'info',
    CHIP_SELECT = 'chip_select',
    CHIP_SELECT_QUESTION = 'chip_select_question',
}

export enum MessageRole {
    User = 'user',
    Bot = 'bot',
}

export interface IMessage {
    id: number;
    text: string;
    role: MessageRole;
    type: MessageType;
    date: Date;
    isLoading: boolean;
    isTypingComplete: boolean;
}

export interface Option {
    label: string;
    value: string;
}

export interface QuestionMessageType extends IMessage {
    question: string;
    options: string[];
}

export class Message {
    private static COUNTER: number = 0;
    private static LOADING_ID: number = -2;

    public static readonly EMPTY: IMessage = {
        id: -1,
        text: '',
        role: MessageRole.Bot,
        type: MessageType.INVALID,
        date: new Date(),
        isLoading: false,
        isTypingComplete: false,
    };

    public static create(
        text: string,
        type: MessageType,
        role: MessageRole,
        isTypingComplete?: boolean
    ): IMessage {
        return {
            id: Message.COUNTER++,
            text: text,
            role: role,
            type: type,
            date: new Date(),
            isLoading: false,
            isTypingComplete: isTypingComplete !== undefined ? isTypingComplete : false,
        };
    }

    public static createQuestion(
        text: string,
        question: string,
        options: string[]
    ): QuestionMessageType {
        let type: MessageType;
        if (options.length === 0) {
            type = MessageType.OPEN;
        } else {
            type = MessageType.CHIP_SELECT_QUESTION;
            if (!options.includes('Other')) {
                options.push('Other');
            }
        }

        return {
            ...Message.create(text, type, MessageRole.Bot),
            question: question,
            options: options,
        };
    }

    public static LOADING: IMessage = {
        id: Message.LOADING_ID,
        text: '',
        role: MessageRole.Bot,
        type: MessageType.LOADING,
        date: new Date(),
        isLoading: true,
        isTypingComplete: false,
    };
}
