import React, { useState } from 'react';
import { Flex, Form, Input, Typography } from 'antd';

import styles from '../../../../styles/screen/banner/LeaveEmailBox.module.css';
import CircleButton from '../../../CircleButton';
import Config from '../../../../config/config';
import Auth from '../../../../auth/Auth';

interface LeaveEmailBoxProps {
    onSendButtonClick: (email: string) => void;
    onCloseButtonClick?: () => void;
}

const { Text } = Typography;

const LeaveEmailBox: React.FC<LeaveEmailBoxProps> = ({
    onSendButtonClick,
    onCloseButtonClick = () => {},
}) => {
    const [form] = Form.useForm();
    const [submitPressed, setSubmitPressed] = useState<boolean>(false);

    const handleSubmit = () => {
        form.validateFields()
            .then((values) => {
                onSendButtonClick(values.email);
                setSubmitPressed(true);
            })
            .catch((_) => {});
    };

    const renderLeaveEmail = () => {
        return (
            <Flex vertical align={'center'} className={styles.leaveEmailBox}>
                <Text className={styles.leaveEmailBoxTitle}>{Config.leaveEmailBox.title}</Text>
                <Text className={styles.leaveEmailBoxSubtitle}>
                    {Config.leaveEmailBox.subTitle}
                </Text>
                <Form form={form} className={styles.formContainer}>
                    <Form.Item
                        name="email"
                        validateFirst={true}
                        rules={[
                            {
                                required: true,
                                message: Config.leaveEmailBox.noEmailError,
                                validateTrigger: ['submit'],
                            },
                            {
                                type: 'email',
                                message: Config.leaveEmailBox.badEmailError,
                                validateTrigger: ['onBlur', 'onSubmit'],
                            },
                        ]}
                    >
                        <Flex
                            justify={'space-between'}
                            align={'center'}
                            className={styles.leaveEmailBoxInputContainer}
                        >
                            <Input
                                className={styles.leaveEmailBoxInput}
                                placeholder={Config.leaveEmailBox.inputPlaceHolder}
                                variant={'borderless'}
                            />
                            <CircleButton onClick={handleSubmit} />
                        </Flex>
                    </Form.Item>
                </Form>
            </Flex>
        );
    };
    const renderThankYou = () => {
        return (
            <Flex vertical align={'center'} className={styles.leaveEmailBox}>
                <Text className={styles.leaveEmailBoxTitle}>
                    {Config.leaveEmailBox.thankYouTitle}
                </Text>
                <Text className={styles.leaveEmailBoxSubtitle}>
                    {Config.leaveEmailBox.thankYouSubTitle}
                </Text>
            </Flex>
        );
    };

    if (Auth.loggedIn) {
        onSendButtonClick(Auth.getUser().$email);
    }

    return submitPressed || Auth.loggedIn ? renderThankYou() : renderLeaveEmail();
};

export default LeaveEmailBox;
