import mixpanel from 'mixpanel-browser';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Button, Input } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { IMessage, Message, MessageRole, MessageType, QuestionMessageType } from './ChatbotTypes';
import { ResponseTypeEnum, CHAT_TIMEOUT } from '../../config/constants';
import {
    firstPrompt,
    FirstPromptResponse,
    FollowupQuestionResponse,
    GatherUserInfoResponse,
    MixedResponse,
    NPrompt,
    NPromptResponse,
    ResponseType,
    ToolNamesResponse,
    InvalidRequestResponse,
} from '../../core/Core';
import StandardMessage from './message/StandardMessage';
import QuestionMessage from './message/QuestionMessage/QuestionMessage';
import { AnalyticsEvent, AnalyticsEventName } from '../../analytics/AnalyticsEvent';
import styles from '../../styles/screen/SplitScreen/chat/Chat.module.css';
import Logger from '../../logging/logger';
import Config from '../../config/config';
import Mixpanel from '../../analytics/Mixpanel';

interface ChatProps {
    initialPrompt: string;
    isAutoPrompt: boolean;
    setisAutoPrompt: React.Dispatch<React.SetStateAction<boolean>>;
    onHeadlineUpdate: (headline: string) => void;
    onToolNamesUpdate: (title: string, toolNames: string[]) => void;
    showBanner: boolean;
}

const ChatComponent: React.FC<ChatProps> = ({
    initialPrompt,
    isAutoPrompt,
    setisAutoPrompt,
    onHeadlineUpdate,
    onToolNamesUpdate,
    showBanner,
}) => {
    const [displayedMsgs, setDisplayedMsgs] = useState<IMessage[]>([]);
    const [loadingChat, setloadingChat] = useState<boolean>(false);
    const [msgQueue, setMsgQueue] = useState<IMessage[]>([]);
    const [inputText, setInputText] = useState<string>('');
    const [currentTypingMessage, setCurrentTypingMessage] = useState<IMessage | null>(null);
    const [sendButtonState, setSendButtonState] = useState<boolean>(false);
    const [timedOut, setTimedOut] = useState<boolean>(false);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const lastUserMessageTime = useRef<number>(0); /* milliseconds */
    const lastBotMessageTime = useRef<number>(0); /* milliseconds */

    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            setTimedOut(true);
            addToQueue(
                Message.create(Config.chat.timeoutMessage, MessageType.INFO, MessageRole.Bot)
            );
        }, CHAT_TIMEOUT);
    }, [msgQueue]);

    useEffect(() => {
        scrollToBottom();
    }, [displayedMsgs, currentTypingMessage, showBanner]);

    useEffect(() => {
        const handleFirstPrompt = async (prompt: string, is_auto_prompt: boolean) => {
            try {
                handlePrompt(prompt, isAutoPrompt, firstPrompt(prompt));
            } catch (error) {
                if (error === 'request aborted') {
                    console.debug('Request aborted');
                } else {
                    Logger.error('Error in handleFirstPrompt', error);
                    throw error;
                }
            }
        };

        if (initialPrompt) {
            lastUserMessageTime.current = Date.now();
            handleFirstPrompt(initialPrompt, isAutoPrompt);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialPrompt]);

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        if (msgQueue.length > 0 && !currentTypingMessage) {
            const nextMsg = msgQueue[0];
            setMsgQueue(msgQueue.slice(1));
            setCurrentTypingMessage(nextMsg);
            displayMessage(nextMsg);
        }
        if (msgQueue.length === 0 && !currentTypingMessage) {
            setloadingChat(false);
        }
    }, [msgQueue, currentTypingMessage]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const logUserMessage = async (msg: IMessage, preMsg: IMessage = Message.EMPTY) => {
        lastUserMessageTime.current = Date.now();
        AnalyticsEvent.create(AnalyticsEventName.CHAT_RESPONSE_USER)
            .set('message', msg.text)
            .set('message_number', msg.id)
            .set('last_message', preMsg.text)
            .set('followup', preMsg.role === MessageRole.User)
            .set('message_type', msg.type.toString())
            .set('$duration', Date.now() - lastBotMessageTime.current)
            .mixpanelTrack()
            .googleAnalyticsTrack();
    };

    const logAutoMessage = async (msg: IMessage, preMsg: IMessage = Message.EMPTY) => {
        lastUserMessageTime.current = Date.now();
        AnalyticsEvent.create(AnalyticsEventName.CHAT_RESPONSE_AUTO)
            .set('message', msg.text)
            .set('message_number', msg.id)
            .set('last_message', preMsg.text)
            .set('followup', preMsg.role === MessageRole.User)
            .set('message_type', msg.type.toString())
            .set('$duration', Date.now() - lastBotMessageTime.current)
            .mixpanelTrack()
            .googleAnalyticsTrack();
    };

    const enableUserInput = useCallback(() => {
        return inputText.trim() !== '' && loadingChat === false;
    }, [inputText, loadingChat]);

    const enableQuestionOption = useCallback(() => {
        return loadingChat === false;
    }, [loadingChat]);

    useEffect(() => {
        setSendButtonState(enableUserInput() && !timedOut);
    }, [enableUserInput, timedOut]);

    const handleSendMessage = async () => {
        if (enableUserInput()) {
            try {
                void handlePrompt(
                    inputText,
                    isAutoPrompt,
                    NPrompt(ResponseTypeEnum.FREE_TEXT, inputText)
                );
            } catch (error) {
                if (error === 'request aborted') {
                    console.debug('Request aborted');
                } else {
                    Logger.error('Error in handlePrompt', error);
                    throw error;
                }
            }
            setInputText('');
        }
    };

    async function questionOptionClicked(question: string, user_input: string) {
        if (enableQuestionOption()) {
            try {
                void handlePrompt(
                    user_input,
                    isAutoPrompt,
                    NPrompt(ResponseTypeEnum.FROM_LIST, user_input)
                );
            } catch (error) {
                if (error === 'request aborted') {
                    console.debug('Request aborted');
                } else {
                    Logger.error('Error in handlePrompt', error);
                    throw error;
                }
            }
        }
    }

    const handlePrompt = async (
        prompt: string,
        is_auto_prompt: boolean,
        response:
            | Promise<FirstPromptResponse>
            | Promise<NPromptResponse>
            | Promise<InvalidRequestResponse>
    ) => {
        setloadingChat(true);
        const loadingMessageId = addUserMsgAndBotLoading(prompt, is_auto_prompt, MessageType.OPEN);

        const promptResponse: FirstPromptResponse | NPromptResponse | InvalidRequestResponse =
            await response;
        lastBotMessageTime.current = Date.now();
        const msgs = handlePromptResponse(promptResponse);

        if (msgs.length === 0) {
            return promptResponse;
        }
        removeFromDisplayed(loadingMessageId);

        const [firstMsg, ...rest] = msgs;
        if (!currentTypingMessage) {
            setCurrentTypingMessage(firstMsg);
            displayMessage(firstMsg);
        } else {
            addToQueue(firstMsg);
        }
        addToQueue(...rest);
        return promptResponse;
    };

    function handleTypingComplete() {
        setCurrentTypingMessage(null);
    }

    function handlePromptResponse(
        promptResponse: FirstPromptResponse | NPromptResponse | InvalidRequestResponse
    ): IMessage[] {
        let messages: IMessage[] = [];

        function handleToolNamesResponse(promptResponse: ToolNamesResponse): IMessage[] {
            const toolNames = (JSON.parse(promptResponse as unknown as string) as ToolNamesResponse)
                .toolNames;
            const header = (JSON.parse(promptResponse as unknown as string) as ToolNamesResponse)
                .message;
            const title = (JSON.parse(promptResponse as unknown as string) as ToolNamesResponse)
                .headline;
            onHeadlineUpdate(title);

            const message = notifyOnToolNames(header, title, toolNames);
            if (message) {
                return [message];
            }
            return [];
        }

        function handleFollowupQuestionResponse(
            promptResponse: FollowupQuestionResponse
        ): IMessage[] {
            const response = JSON.parse(
                promptResponse as unknown as string
            ) as FollowupQuestionResponse;
            const followup = response as FollowupQuestionResponse;
            return [createBotQuestionMessage(followup.question, followup.options)];
        }

        function handleGatherUserInfoResponse(promptResponse: GatherUserInfoResponse): IMessage[] {
            const response = JSON.parse(
                promptResponse as unknown as string
            ) as GatherUserInfoResponse;
            const gatherUserInfo = response as GatherUserInfoResponse;
            AnalyticsEvent.create(AnalyticsEventName.USER_INFORMATION)
                .set('SW_domain', gatherUserInfo.domain)
                .set('Organization_size', gatherUserInfo.organizationSize)
                .set('Experience_with_domain', gatherUserInfo.experience)
                .set('Relpacement_SW', gatherUserInfo.newSoftware)
                .set('Insufficient_data ', gatherUserInfo.insufficientData)
                .set('Confidence', gatherUserInfo.confidence)
                .mixpanelTrack();

            mixpanel.identify();

            Mixpanel.updateUser({
                SW_domain: gatherUserInfo.domain,
                Organization_size: gatherUserInfo.organizationSize,
                Experience_with_domain: gatherUserInfo.experience,
                Relpacement_SW: gatherUserInfo.newSoftware,
                Insufficient_data: gatherUserInfo.insufficientData,
                Confidence: gatherUserInfo.confidence,
            });
            return [];
        }

        function handleInvalidRequestResponse(promptResponse: InvalidRequestResponse): IMessage[] {
            const response = JSON.parse(
                promptResponse.response as unknown as string
            ) as FollowupQuestionResponse;
            const followup = response as FollowupQuestionResponse;
            return [
                createBotMessage(promptResponse.reason, MessageType.INVALID_USER_INPUT),
                createBotQuestionMessage(followup.question, followup.options),
            ];
        }

        switch (promptResponse.type) {
            case ResponseType.ToolNamesResponse:
                messages = handleToolNamesResponse(promptResponse.response as ToolNamesResponse);
                break;
            case ResponseType.FollowupQuestionResponse:
                messages = handleFollowupQuestionResponse(
                    promptResponse.response as FollowupQuestionResponse
                );
                break;
            case ResponseType.MixedResponse:
                messages = [
                    ...handleToolNamesResponse(
                        (promptResponse.response as MixedResponse).toolNamesResponse
                    ),
                    ...handleFollowupQuestionResponse(
                        (promptResponse.response as MixedResponse).followupQuestionResponse
                    ),
                    ...handleGatherUserInfoResponse(
                        (promptResponse.response as MixedResponse).gatherUserInfoResponse
                    ),
                ];
                break;
            case ResponseType.InvalidRequestResponse:
                messages = handleInvalidRequestResponse(promptResponse as InvalidRequestResponse);
                break;
        }

        const endTime = Date.now();
        for (const msg of messages) {
            AnalyticsEvent.create(AnalyticsEventName.CHAT_RESPONSE_BOT)
                .set('message', msg.text)
                .set('message_number', msg.id)
                .set('message_type', msg.type.toString())
                .set('response_type', promptResponse.type.toString())
                .set('$duration', endTime - lastUserMessageTime.current)
                .mixpanelTrack();
        }

        return messages;
    }

    function notifyOnToolNames(
        message: string,
        title: string,
        names: string[]
    ): IMessage | undefined {
        /* Search for tools. */
        if (!names || names.length === 0) {
            return Message.EMPTY;
        }
        onToolNamesUpdate(title, names);

        return Message.create(message, MessageType.TOOLS_FOUND, MessageRole.Bot);
    }

    function createBotMessage(msg: string | null, type: MessageType): IMessage {
        return Message.create(msg ? msg : '', type, MessageRole.Bot);
    }

    function createUserMessage(msg: string | null, type: MessageType): IMessage {
        return Message.create(msg ? msg : '', type, MessageRole.User);
    }

    function createBotQuestionMessage(question: string | null, options: string[]) {
        return Message.createQuestion(question ? question : '', question ? question : '', options);
    }

    function addUserMsgAndBotLoading(
        input: string,
        is_auto_prompt: boolean,
        type: MessageType
    ): number {
        const loadingMessage = Message.LOADING;
        const userMessage = createUserMessage(input, type);
        if (is_auto_prompt) {
            void logAutoMessage(userMessage, displayedMsgs[displayedMsgs.length - 1]);
            setisAutoPrompt(false);
        } else {
            void logUserMessage(userMessage, displayedMsgs[displayedMsgs.length - 1]);
        }
        displayMessage(userMessage);
        displayMessage(loadingMessage);

        return loadingMessage.id;
    }

    function displayMessage(msg: IMessage) {
        setDisplayedMsgs((prev) => [...prev, msg]);
    }

    function addToQueue(...msgs: IMessage[]) {
        setMsgQueue((prev) => [...prev, ...msgs]);
    }

    function removeFromDisplayed(msgId: number) {
        setDisplayedMsgs((prev) => prev.filter((m) => m.id !== msgId));
    }

    return (
        <div className={styles.chat}>
            <div className={styles.chatLog}>
                {displayedMsgs.map((message) =>
                    message.type === MessageType.CHIP_SELECT_QUESTION ? (
                        <QuestionMessage
                            key={message.id}
                            msg={message as QuestionMessageType}
                            onOptionClick={questionOptionClicked}
                            isTyping={currentTypingMessage?.id === message.id}
                            onTypingComplete={handleTypingComplete}
                        />
                    ) : (
                        <StandardMessage
                            key={message.id}
                            msg={message}
                            isTyping={currentTypingMessage?.id === message.id}
                            onTypingComplete={handleTypingComplete}
                        />
                    )
                )}
                <div ref={messagesEndRef} />
            </div>
            <div className="p-4 border-t">
                <div className="flex space-x-2">
                    <Input
                        value={inputText}
                        onChange={(e) => {
                            setInputText(e.target.value);
                        }}
                        onPressEnter={handleSendMessage}
                        placeholder="Type a message..."
                        className="flex-1"
                        disabled={timedOut}
                        data-hj-allow
                    />
                    <Button
                        type="primary"
                        icon={<SendOutlined />}
                        onClick={handleSendMessage}
                        disabled={!sendButtonState}
                    >
                        Send
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ChatComponent;
