import React from 'react';
import { Alert, Flex, Modal } from 'antd';
import styles from '../../../../styles/screen/banner/Banner.module.css';
import LeaveEmailBox from '../LeaveEmailBox/LeaveEmailBox';
import { AnalyticsEvent, AnalyticsEventName } from '../../../../analytics/AnalyticsEvent';
import Config from '../../../../config/config';

interface BannerProps {
    showBanner: boolean;
    setShowBanner: (show: boolean) => void;
    onBannerClose: () => void;
}

const Banner: React.FC<BannerProps> = ({ showBanner = true, setShowBanner, onBannerClose }) => {
    const [showLeaveEmailBox, setShowLeaveEmailBox] = React.useState<boolean>(false);
    const [emailSent, setEmailSent] = React.useState<boolean>(false);
    function handleBannerLinkClick() {
        AnalyticsEvent.create(AnalyticsEventName.BANNER_CLICKED).mixpanelTrack();
        setShowLeaveEmailBox(true);
    }

    function handleLeaveEmailBoxButtonClick(email: string) {
        setEmailSent(true);
        AnalyticsEvent.create(AnalyticsEventName.BANNER_SEND_EMAIL_BUTTON)
            .set('email', email)
            .mixpanelTrack()
            .googleAnalyticsTrack();
    }

    function handleCloseButtonClick() {
        setShowLeaveEmailBox(false);
        if (emailSent) {
            setShowBanner(false);
            onBannerClose();
        }
    }

    const modalRender = (modal: any) => (
        <Flex align={'center'} justify={'center'} className={'h-full'}>
            {modal}
        </Flex>
    );

    return (
        <div hidden={!showBanner} className={styles.banner}>
            <Alert
                banner={true}
                closable={true}
                showIcon={false}
                message={
                    <div className={styles.bannerTextContainer}>
                        <span className={styles.bannerBaseFont}>{Config.banner.msg}</span>
                        <span>&nbsp;&nbsp;</span>
                        <span className={styles.bannerLinkFont} onClick={handleBannerLinkClick}>
                            {Config.banner.linkTitle}
                        </span>
                    </div>
                }
                closeIcon={
                    <img
                        src={'/graphics/closeIcon.png'}
                        alt={'close'}
                        className={styles.bannerCloseButton}
                        onClick={onBannerClose}
                    />
                }
                className={styles.banner}
            />
            <Modal
                open={showLeaveEmailBox}
                footer={null}
                title={null}
                maskClosable={true}
                className={styles.bannerModal}
                styles={{ body: { padding: 0 } }}
                centered={true}
                onCancel={() => setShowLeaveEmailBox(false)}
                modalRender={modalRender}
                width={620}
                closeIcon={
                    <img
                        src={'/graphics/closeIcon.png'}
                        alt={'close'}
                        className={styles.bannerCloseButton}
                        onClick={handleCloseButtonClick}
                    />
                }
            >
                <LeaveEmailBox
                    onSendButtonClick={handleLeaveEmailBoxButtonClick}
                    onCloseButtonClick={handleCloseButtonClick}
                />
            </Modal>
        </div>
    );
};

export default Banner;
