import React, { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import HomeScreen from '../screens/HomeScreen/HomeScreen';
import SplitScreen from '../screens/SplitScreen/SplitScreen';
import { ScreenName, useAppContext } from '../../providers/ContextProvider/ContextProvider';
import { AnalyticsEvent, AnalyticsEventName } from '../../analytics/AnalyticsEvent';
import styles from '../../styles/ScreenTransition.module.css';
import { URLHandler } from '../../core/URLHandler';

const ScreenTransition: React.FC = () => {
    const { currentScreen, setCurrentScreen } = useAppContext();
    const [initialPrompt, setPromptState] = React.useState('');
    const [isAutoPrompt, setIsAutoPrompt] = React.useState(false);
    const [showPopup, setShowPopup] = React.useState(false);
    const [initialTerm, setInitialTerm] = React.useState('');

    const screenVariants = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
    };

    const setInitialPrompt = (prompt: string) => {
        setPromptState(prompt);
        return prompt;
    };

    useEffect(() => {
        if (URLHandler.firstScreenConfig.autoPrompt !== '') {
            setInitialPrompt(URLHandler.firstScreenConfig.autoPrompt);
        }

        setIsAutoPrompt(URLHandler.firstScreenConfig.isAutoPrompt);
        setInitialTerm(URLHandler.firstScreenConfig.initialTerm);

        switch (URLHandler.firstScreenConfig.screenName) {
            case ScreenName.HOME:
                setCurrentScreen(ScreenName.HOME);
                setShowPopup(false);
                break;
            case ScreenName.SPLIT:
                setCurrentScreen(ScreenName.SPLIT);
                setShowPopup(true);
                break;
            default:
                break;
        }
    }, [setCurrentScreen]);

    const onPromptSend = (prompt: string): void => {
        setInitialPrompt(prompt);
        setCurrentScreen(ScreenName.SPLIT);
    };

    return (
        <div className={styles.mainContainer} id="dci">
            <AnimatePresence mode="wait">
                {currentScreen === 'home' && (
                    <motion.div
                        key="home"
                        variants={screenVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        transition={{ duration: 0.5 }}
                        className={styles.homeScreen}
                    >
                        <HomeScreen onPromptSend={onPromptSend} />
                    </motion.div>
                )}
                {currentScreen === 'split' && (
                    <motion.div
                        key="split"
                        variants={screenVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        transition={{ duration: 0.5 }}
                        className={styles.splitScreen}
                    >
                        <SplitScreen
                            initialPrompt={initialPrompt}
                            isAutoPrompt={isAutoPrompt}
                            setIsAutoPrompt={setIsAutoPrompt}
                            onTransition={() => {}}
                            showPopup={showPopup}
                            setShowPopup={setShowPopup}
                            initialTerm={initialTerm}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default ScreenTransition;
