import Logger from './logging/logger';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './Index.css';
import App from './components/App';
import { fetchClientInitKeys } from './core/Core';
import HotjarAnalytics from './analytics/Hotjar';
import Mixpanel from './analytics/Mixpanel';
import GoogleAnalytics from './analytics/GoogleAnalytics';
import Contentful from './content_mangment/contentful';
import Config from './config/config';
import Auth from './auth/Auth';
import { sendMixpanelStatus } from './core/Core';
import ServerLogger from './logging/serverLogger';
import URLParams from './core/URLHandler';
import { CONTENTFUL_CONFIG } from './config/constants';

const container = document.getElementById('root');
const root = createRoot(container!);

function main() {
    URLParams.collectURLParams();
    Logger.registerLogger(new ServerLogger());

    fetchClientInitKeys()
        .then(async (tokens) => {
            await Contentful.init(
                tokens?.contentfulSpaceId || '',
                tokens?.contentfulAccessToken || ''
            );
            await Config.init();
            return tokens;
        })
        .then(async (tokens) => {
            await Auth.loadUserFromLocalStorage();
            return tokens;
        })
        .then((tokens) => {
            if (tokens.mixpanelToken) {
                Mixpanel.init(tokens?.mixpanelToken);
            } else {
                Logger.error('Mixpanel token is not available');
                void sendMixpanelStatus('Mixpanel missing token');
            }
            GoogleAnalytics.init(tokens?.googleAnalyticsMeasurementId || '');
            HotjarAnalytics.init(tokens?.hotjarSiteId || 0);
            Auth.init(
                tokens?.googleAuthClientId || '',
                tokens?.linkedinClientId || '',
                tokens?.facebookAppId || ''
            );

            Config.setUserDependentConfig();
            Mixpanel.trackExperiment(Auth.getUser().variant);
        })
        .then(() => {
            GoogleAnalytics.identify();
            Mixpanel.startSession();
            HotjarAnalytics.identify();
            void sendMixpanelStatus('Mixpanel initialized');
        })
        .then(() => {
            root.render(<App />);
        })
        .catch((error) => {
            Logger.error(error.message);
        });
}

main();
