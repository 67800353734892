import { useCallback, useRef } from 'react';

export const renderURL = (domain: string) =>
    domain.startsWith('http://') || domain.startsWith('https://') ? domain : `https://${domain}`;

export const useOneTimeCallback = <T extends (...args: any[]) => any>(
    callback: T,
    dependencies: any[] = []
): T => {
    const hasRunMap = useRef(new Map());

    // noinspection com.intellij.reactbuddy.ExhaustiveDepsInspection
    return useCallback(
        (...args: Parameters<T>) => {
            const depsKey = JSON.stringify(dependencies);

            if (!hasRunMap.current.has(depsKey)) {
                hasRunMap.current.set(depsKey, true);
                return callback(...args);
            }

            return undefined;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [callback, ...dependencies]
    ) as T;
};

export const isMobile = () => {
    // Multiple detection methods for reliability
    const checks = {
        // Check touch capability (most mobile devices have touch)
        hasTouch: 'ontouchstart' in window || navigator.maxTouchPoints > 0,

        // Check screen size
        isSmallScreen: window.matchMedia('(max-width: 768px)').matches,

        // Check mobile-specific navigator properties
        userAgentMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        ),

        // Check platform (to exclude tablets and touch-enabled laptops)
        platformMobile: !/Win|Mac|Linux/i.test(navigator.platform),
    };

    // Exclude desktop devices that might have touch capability
    const isMac = /Mac/i.test(navigator.platform);
    const isWindows = /Win/i.test(navigator.platform);
    const isDesktop = isMac || isWindows;

    return (
        (checks.hasTouch && checks.isSmallScreen && !isDesktop) ||
        (checks.userAgentMobile && !isDesktop)
    );
};
