import { api, basicApi } from '../utils/ServerUtils';
import URLParams from './URLHandler';

export interface ToolData {
    name: string;
    domain: string;
    logo: string;
    summary: string;
    matchScore: number;
    pricing: number;
    organizationSize: string;
    love: string;
    hate: string;
    supportLevel: string;
    easeOfIntegration: string;
}

export interface ToolNamesResponse {
    toolNames: string[];
    message: string;
    headline: string;
}

export interface FollowupQuestionResponse {
    topic: string;
    question: string;
    options: string[];
}

export interface GatherUserInfoResponse {
    domain: string;
    organizationSize: string;
    experience: string;
    newSoftware: string;
    insufficientData: boolean;
    confidence: string;
}

export interface FirstPromptResponse {
    type: ResponseType;
    response: ToolNamesResponse | FollowupQuestionResponse;
}

export interface InvalidRequestResponse {
    reason: string;
    type: ResponseType;
    response: FollowupQuestionResponse;
}

export interface MixedResponse {
    toolNamesResponse: ToolNamesResponse;
    followupQuestionResponse: FollowupQuestionResponse;
    gatherUserInfoResponse: GatherUserInfoResponse;
}

export enum ResponseType {
    ToolNamesResponse = 'ToolNamesResponse',
    FollowupQuestionResponse = 'FollowupQuestionResponse',
    MixedResponse = 'MixedResponse',
    InvalidRequestResponse = 'InvalidRequestResponse',
}

export interface NPromptResponse {
    type: ResponseType;
    response: ToolNamesResponse | FollowupQuestionResponse | MixedResponse;
}

export interface ClientKeysResponse {
    mixpanelToken: string;
    hotjarSiteId: number;
    googleAnalyticsMeasurementId: string;
    contentfulSpaceId: string;
    contentfulAccessToken: string;
    googleAuthClientId: string;
    linkedinClientId: string;
    facebookAppId: string;
}

export async function firstPrompt(prompt: string): Promise<FirstPromptResponse> {
    const apiResponse = await api('first_prompt', { prompt, utm_params: URLParams.getUTMParams() });
    return apiResponse.response;
}

export async function NPrompt(type: string, user_input: string): Promise<NPromptResponse> {
    const apiResponse = await api('n_prompt', { type, user_input });
    return apiResponse.response;
}

export async function getTool(
    toolName: string,
    updateOnly: boolean = false,
    signal?: AbortSignal
): Promise<ToolData> {
    const apiResponse = await api(
        'get_tool',
        { tool_name: toolName, update_only: updateOnly },
        signal
    );
    return apiResponse.response.tool;
}

export async function getTools(toolNames: string[]): Promise<ToolData[]> {
    const apiResponse = await api('get_tools', { tool_names: toolNames });
    return apiResponse.response.tools;
}

export async function sendMixpanelStatus(status: string) {
    await api('mixpanel_status', { status });
}

export async function fetchClientInitKeys(): Promise<ClientKeysResponse> {
    try {
        const apiResponse = await basicApi('client_init_keys');
        return apiResponse.response as ClientKeysResponse;
    } catch (e) {
        throw new Error('Failed to fetch client init keys');
    }
}

export async function logToServer(message: string, level: string) {
    await api('client_side_log', { message, level });
}
