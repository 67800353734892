import mixpanel from 'mixpanel-browser';
import { AnalyticsEvent, AnalyticsEventName } from './AnalyticsEvent';
import { EXPERIMENT_NAME, NOT_AVAILABLE } from '../config/constants';
import Auth, { UserDetails, Variant } from '../auth/Auth';
import Logger from '../logging/logger';
import URLParams from '../core/URLHandler';

class Mixpanel {
    public static token: string = '';

    static init(token: string): void {
        Mixpanel.token = token;
        try {
            mixpanel.init(token, {
                debug: false,
                track_pageview: true,
                persistence: 'localStorage',
            });
        } catch (error) {
            Logger.error('Error in initializing Mixpanel:', error);
        }

        const params = URLParams.getUTMParams();
        if (!!!params) {
            URLParams.UTM_KEYS.forEach((key) => mixpanel.unregister(key));
        } else {
            URLParams.UTM_KEYS.forEach((key) => {
                if (params[key] !== undefined) {
                    mixpanel.register({ [key]: params[key] });
                }
            });
        }
    }

    static startSession(): void {
        Mixpanel.identify();
        Mixpanel.register(
            Mixpanel.MixpanelPropertyName.GIT_TAG,
            process.env.REACT_APP_GIT_TAG || NOT_AVAILABLE
        );

        Mixpanel.register(Mixpanel.MixpanelPropertyName.USER_TOKEN, Auth.getUserToken());
        AnalyticsEvent.create(AnalyticsEventName.NOLOS_SESSION_START).mixpanelTrack();

        if (!Mixpanel.isIdentified()) {
            Mixpanel.updateUser({
                userToken: Auth.getUserToken(),
                $name: Auth.getUser().name,
                $email: Auth.getUser().$email,
                $avatar: Auth.getUser().pictureUrl,
                ssoProvider: Auth.getUser().provider,
            });

            const params = URLParams.getUTMParams();
            Mixpanel.setUTMParam('$Initial UTM Medium', params.utm_medium);
            Mixpanel.setUTMParam('$Initial UTM Campaign', params.utm_campaign);
            Mixpanel.setUTMParam('$Initial UTM Content', params.utm_content);
            Mixpanel.setUTMParam('$Initial UTM Source', params.utm_source);
            Mixpanel.setUTMParam('$Initial UTM Term', params.utm_term);
            Mixpanel.setUTMParam('$Initial UTM GroupID', params.utm_groupid);
        }
    }

    static setUTMParam(paramName: string, value: string | undefined): void {
        if (value) {
            Mixpanel.updateUser({ [paramName]: value });
        }
    }

    static isEmail(str: string): boolean {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str);
    }

    static isIdentified(): boolean {
        return Mixpanel.isEmail(mixpanel.get_distinct_id());
    }

    static identify(id?: string): void {
        if (id && mixpanel.get_distinct_id() === id) {
            return;
        }

        if (id) {
            if (this.isIdentified()) {
                mixpanel.reset();
            }

            mixpanel.identify(Auth.getUser().id);
        } else {
            // For some reason, it fixes a bug in setting user props without it, cant set the props
            mixpanel.identify();
            Auth.setUserId(mixpanel.get_distinct_id());
        }
    }

    static updateUser(properties: Record<string, any>, superProps?: Record<string, any>) {
        mixpanel.people.set(properties);
        if (superProps) {
            mixpanel.register(superProps);
        }
    }

    static getUserProperties(): Record<string, any> {
        return mixpanel.get_distinct_id();
    }

    static track(name: string, properties?: Record<string, any>): void {
        try {
            mixpanel.track(name, properties);
        } catch (error) {
            if (
                error instanceof Error &&
                error.message ===
                    "Cannot read properties of undefined (reading 'disable_all_events')"
            ) {
                Logger.error('Mixpanel is not initialized');
            }
        }
    }

    static register(propName: string, propValue: string): void {
        mixpanel.register({
            [propName]: propValue,
        });
    }

    static handleLogIn(logInId: string, details: UserDetails): void {
        // 1. Store anonymous user properties before identification
        const mpState = JSON.parse(
            localStorage.getItem('mp_' + Mixpanel.token + '_mixpanel') || '{}'
        );
        const anonProperties = mpState.__mps || {}; // People properties
        const superProps = mixpanel.get_property('super') || {};

        const properties = {
            ...anonProperties,
            $name: details.name,
            $email: details.$email,
            $avatar: details.pictureUrl,
            ssoProvider: details.provider,
        };
        mixpanel.alias(logInId);
        Mixpanel.identify(logInId);

        this.updateUser(properties, superProps);
    }

    public static trackExperiment(variant: Variant) {
        Mixpanel.track('$experiment_started', {
            'Experiment name': EXPERIMENT_NAME,
            'Variant Name': variant.toString(),
        });
    }
}

namespace Mixpanel {
    export enum MixpanelPropertyName {
        SESSION_ID = 'session_id',
        GIT_TAG = 'git_tag',
        USER_TOKEN = 'user_token',
    }
}

export default Mixpanel;
