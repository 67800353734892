import { collectUserData } from '../logging/UserDataCollector';
import { AnalyticsEvent, AnalyticsEventName } from '../analytics/AnalyticsEvent';
import { API_TIMEOUT, API_RETRIES, API_RETRY_DELAY } from '../config/constants';
import Logger from '../logging/logger';

export interface ServerAPIResponse {
    sessionId: string;
    response: any;
}

class HTTPError extends Error {
    status: number;

    constructor(message: string, status: number) {
        super(message);
        this.name = 'HTTP Error';
        this.status = status;
    }
}

class TimeoutError extends Error {
    duration: number;

    constructor(message: string, duration: number) {
        super(message);
        this.name = 'Timeout Error';
        this.duration = duration;
    }
}

function fetchWithTimeout(
    url: string,
    options: RequestInit = {},
    timeout = API_TIMEOUT,
    retries = API_RETRIES,
    retryDelay = API_RETRY_DELAY,
    signal?: AbortSignal
): Promise<Response> {
    const timeoutController = new AbortController();
    const timeoutId = setTimeout(() => timeoutController.abort('timeout'), timeout);
    const now = Date.now();

    let attempt = 0;

    // Combine both signals if external signal is provided
    let combinedSignal = timeoutController.signal;
    if (signal) {
        combinedSignal = new AbortSignal();
        // Listen to both signals
        [timeoutController.signal, signal].forEach((s) => {
            s.addEventListener('abort', () => {
                clearTimeout(timeoutId);
                if (!combinedSignal.aborted) {
                    (combinedSignal as any).abort();
                }
            });
        });
    }
    const executeFetch = (): Promise<Response> => {
        attempt++;

        return fetch(url, {
            ...options,
            signal: combinedSignal,
        }).then(
            (response) => {
                clearTimeout(timeoutId);
                return response;
            },
            (error) => {
                clearTimeout(timeoutId);
                if (error === 'timeout') {
                    const delta = Date.now() - now;
                    throw new TimeoutError('fetchWithTimeout: Request timed out.', delta);
                } else if (error === 'request aborted') {
                    console.debug('Request aborted');
                    throw error;
                }
                // For other errors, attempt retry if we haven't exceeded max retries
                if (attempt < retries) {
                    console.debug(`Retry attempt ${attempt} of ${retries}`);
                    return new Promise((resolve) => setTimeout(resolve, retryDelay * attempt)).then(
                        executeFetch
                    );
                }

                throw error;
            }
        );
    };
    return executeFetch();
}

async function request(
    endpoint: string,
    data: any = {},
    includeProperties: boolean = false,
    signal?: AbortSignal
): Promise<ServerAPIResponse> {
    const headers: Record<string, string> = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    if (includeProperties) {
        headers['X-User-Data'] = JSON.stringify(collectUserData());
    }

    try {
        const response = await fetchWithTimeout(`/api/${endpoint}`, {
            method: 'POST',
            headers,
            body: JSON.stringify({
                ...data,
            }),
            signal,
        });

        if (!response.ok) {
            const statusCode = response.status;
            const errorData = await response.json();
            const errorMessage = errorData.error || `HTTP error! status: ${statusCode}`;

            throw new HTTPError(errorMessage, statusCode);
        }

        return (await response.json()) as ServerAPIResponse;
    } catch (e) {
        console.debug('Request failed: ', e);
        let status: number = 0;
        let errorMessage: string = 'An unknown error occurred';
        let logError: boolean = false;

        if (e instanceof HTTPError) {
            // Handle network errors
            Logger.error('Server error: ', e.status, 'message: ', e.message);
            errorMessage = e.message;
            status = e.status;
            logError = true;
        } else if (e instanceof TimeoutError) {
            Logger.error('Timeout error. duration: ', e.duration, 'message: ', e.message);
            errorMessage = e.message;
            logError = true;
        }

        if (logError) {
            try {
                AnalyticsEvent.create(AnalyticsEventName.SERVER_REQUEST_FAILED)
                    .set('Status_code', status)
                    .set('error_name', e instanceof Error ? e.name : 'unknown')
                    .set('error_message', errorMessage)
                    .set('request', endpoint)
                    .mixpanelTrack();
            } catch (analyticsError) {
                Logger.error(`Failed to track server request failure: ${analyticsError}`);
            }
        }

        throw e;
    }
}

export async function api(
    endpoint: string,
    data: any = {},
    signal?: AbortSignal
): Promise<ServerAPIResponse> {
    return request(endpoint, data, true);
}

export async function basicApi(endpoint: string, data: any = {}): Promise<ServerAPIResponse> {
    return request(endpoint, data, false);
}
