import React from 'react';
import { Typography, Space } from 'antd';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import type { Document } from '@contentful/rich-text-types';
import { EntryFieldTypes as EFT } from 'contentful';
import { SpaceSize } from 'antd/es/space';

const { Title, Text } = Typography;

type ResolvedRichText = Document;

interface ContentfulRichTextProps {
    content: EFT.RichText | ResolvedRichText;
    className?: string;
    spaceSize?: SpaceSize;
    listItemSpacing?: number;
}

const ContentfulRichText: React.FC<ContentfulRichTextProps> = ({
    content,
    className,
    spaceSize = 'small',
    listItemSpacing = 0,
}) => {
    // Ensure we're working with a Document object
    const richTextContent: Document = content as unknown as Document;

    const options = {
        renderMark: {
            [MARKS.BOLD]: (text: React.ReactNode) => <Text strong>{text}</Text>,
            [MARKS.ITALIC]: (text: React.ReactNode) => <Text italic>{text}</Text>,
            [MARKS.UNDERLINE]: (text: React.ReactNode) => <Text underline>{text}</Text>,
            [MARKS.CODE]: (text: React.ReactNode) => <Text code>{text}</Text>,
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: any, children: React.ReactNode) => <span>{children}</span>,

            [BLOCKS.HEADING_1]: (node: any, children: React.ReactNode) => (
                <Title level={1}>{children}</Title>
            ),
            [BLOCKS.HEADING_2]: (node: any, children: React.ReactNode) => (
                <Title level={2}>{children}</Title>
            ),
            [BLOCKS.HEADING_3]: (node: any, children: React.ReactNode) => (
                <Title level={3}>{children}</Title>
            ),
            [BLOCKS.HEADING_4]: (node: any, children: React.ReactNode) => (
                <Title level={4}>{children}</Title>
            ),
            [BLOCKS.HEADING_5]: (node: any, children: React.ReactNode) => (
                <Title level={5}>{children}</Title>
            ),
            [BLOCKS.UL_LIST]: (node: any, children: React.ReactNode) => (
                <ul style={{ listStyleType: 'disc', marginLeft: 20 }}>{children}</ul>
            ),
            [BLOCKS.OL_LIST]: (node: any, children: React.ReactNode) => (
                <ol style={{ listStyleType: 'decimal', marginLeft: 20 }}>{children}</ol>
            ),
            [BLOCKS.LIST_ITEM]: (node: any, children: React.ReactNode) => (
                <li style={{ marginBottom: `${listItemSpacing}px` }}>{children}</li>
            ),
            [BLOCKS.QUOTE]: (node: any, children: React.ReactNode) => (
                <blockquote
                    style={{ borderLeft: '4px solid #d9d9d9', paddingLeft: 16, margin: '16px 0' }}
                >
                    {children}
                </blockquote>
            ),
            [BLOCKS.HR]: () => <div style={{ border: '1px solid #d9d9d9', margin: '24px 0' }} />,
            [INLINES.HYPERLINK]: (node: any, children: React.ReactNode) => (
                <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
                    {children}
                </a>
            ),
        },
    };

    return (
        <Space
            direction="vertical"
            size={spaceSize}
            className={className}
            style={{ width: '100%' }}
        >
            {documentToReactComponents(richTextContent, options)}
        </Space>
    );
};

export default ContentfulRichText;
