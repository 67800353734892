import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';

const { Title } = Typography;

interface LogoProps {
    src: string | null;
    alt: string;
    width?: number;
    href: string;
    className?: string;
}

const Logo: React.FC<LogoProps> = ({ src, alt, width = 64, href, className = '' }) => {
    const [aspectRatio, setAspectRatio] = useState(1);
    const [contentLoaded, setContentLoaded] = useState(false);

    useEffect(() => {
        if (src) {
            const img = new Image();
            img.onload = () => {
                setAspectRatio(img.naturalWidth / img.naturalHeight);
                setContentLoaded(true);
            };
            img.src = src;
        } else {
            // If there's no image, we consider the content (title) as immediately loaded
            setContentLoaded(true);
        }
    }, [src]);

    const height = width / aspectRatio;

    const containerStyle: React.CSSProperties = {
        width: `${width}px`,
        height: `${height}px`,
        maxHeight: '100%',
        opacity: contentLoaded ? 1 : 0,
        transition: 'opacity 0.3s ease-in-out',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    if (!src) {
        return (
            <div style={containerStyle} className={className}>
                <Title
                    level={5}
                    style={{ margin: 0, textAlign: 'center', wordBreak: 'break-word' }}
                >
                    {alt}
                </Title>
            </div>
        );
    }

    return (
        <div style={containerStyle} className={className}>
            <img
                src={src}
                alt={alt}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                }}
            />
        </div>
    );
};

export default Logo;
