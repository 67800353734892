export const CONTENTFUL_CONFIG = {
    ENVIRONMENT: process.env.REACT_APP_CONTENTFUL_ENV || 'stage',
    REFERENCE_INCLUDE_DEPTH: 5,
    ENTRY_IDS: {
        MAIN_SCREEN: '5bKoqRGT60UTADPThSQmKl',
        BANNER_CONFIG: '1TW17bTqABEhK755ef02wV',
        LEAVE_EMAIL_BOX_CONFIG: '6Wdl6LPkvsoVubpuIjhSTD',
        TABLE_CONFIG: '29z6tPS6mnghFV3Yz7dDlB',
        EMPTY_DATA_CONFIG: '1ZKYRti47O5a47rwdOW6Ii',
        CHAT_CONFIG: '5PshDRFjwXo9iiU6vxkRRB',
        LOADER_ANIMATION_CONFIG: '4bD6KYBEJXeGkEJ30sPFeW',
        SIGNUP_CONFIG: '18XA4lsaJfh6oX7tPjmuQe',
        SIGNOUT_CONFIG: '26HyfRvEPVuFCX7QXvQ01w',
        WHITELIST_ID: 'Oe7ldRRhyaigCMH6OH1pA',
        LANDING_POPUP_CONFIG: '53MtbwznK9RZYdfYyaGlSw',
    },
    TYPE_IDS: {
        CONFIGURATION: 'configuration',
    },
};

export enum ResponseTypeEnum {
    FROM_LIST = '0',
    FREE_TEXT = '1',
}

export const HOTJAR_VERSION: number = 6;

export const API_TIMEOUT = 60000; /* msec */
export const API_RETRIES = 3;
export const API_RETRY_DELAY = 500; /* msec */
export const NOT_AVAILABLE = 'N/A';

export const LOGO_SIZE = 85;

export const AVATAR_STORAGE_KEY = 'avatar';
export const FAKE_NAME_PREFIX = '🤖 ';
export const EXPERIMENT_NAME = 'experiment1';

export const CHAT_TIMEOUT = 30 * 60 * 1000; /* msec */
export const LANDING_PROP_PLACEHOLDER_STRING = 'XXX';
