import React, { useEffect, useRef, useState } from 'react';
import { Layout, Button, Image, Typography, Flex, Modal, Avatar, Dropdown } from 'antd';
import classNames from 'classnames';
import Config from '../../config/config';
import styles from '../../styles/screen/Header/Header.module.css';
import SignUp from '../screens/SignUp/SignUp';
import Auth from '../../auth/Auth';
import { UserOutlined } from '@ant-design/icons';
import SignOut from '../screens/SignOut/SignOut';
import { AnalyticsEvent, AnalyticsEventName } from '../../analytics/AnalyticsEvent';
import Logger from '../../logging/logger';

const { Header } = Layout;
const { Title } = Typography;

interface HeaderComponentProps {
    onLogin: () => void;
    onSignOut?: () => void;
    onLogoClick?: () => void;
    bottomSeparator?: boolean;
    logoSize: number;
    isLoggedIn: boolean;
}

const HeaderComponent: React.FC<HeaderComponentProps> = ({
    onLogin,
    onSignOut,
    onLogoClick,
    bottomSeparator,
    logoSize,
    isLoggedIn,
}) => {
    const [showSignUpModal, setShowSignUpModal] = useState(false);
    const [showSignOutDropdown, setShowSignOutDropdown] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [avatarSrc, setAvatarSrc] = useState<string>('');
    const signOutButtonRef = useRef<HTMLButtonElement>(null);
    const headerClasses = classNames(
        styles.header,
        { 'border-b': bottomSeparator !== undefined && bottomSeparator },
        Config.isDevMode ? 'bg-red-600' : 'bg-white'
    );

    useEffect(() => {
        if (isLoggedIn) {
            Auth.getAvatarPictureURL()
                .then((avatar) => {
                    setAvatarSrc(avatar);
                })
                .catch((error) => {
                    Logger.error('Error getting avatar: ', error);
                });
        } else {
            setAvatarSrc('');
        }
    }, [isLoggedIn]);

    const handleSignUp = () => {
        setShowSignUpModal(false);
        setShowSignOutDropdown(false);
        onLogin();
    };

    const handleSignOut = () => {
        setShowSignOutDropdown(true);
    };
    const handleSignUpButtonClick = () => {
        AnalyticsEvent.create(AnalyticsEventName.SIGN_UP_BUTTON_CLICKED).mixpanelTrack();
        setShowSignUpModal(true);
    };

    const handleModalShowChange = (open: boolean) => {
        AnalyticsEvent.create(
            open
                ? AnalyticsEventName.SIGN_UP_WINDOW_SHOWN
                : AnalyticsEventName.SIGN_UP_WINDOW_CLOSED
        ).mixpanelTrack();
    };
    return (
        <Header className={headerClasses}>
            <div className="mx-auto flex flex-row justify-between align-middle items-center h-full">
                <div onClick={onLogoClick} className="cursor-pointer mt-6">
                    <Image
                        src="/graphics/logo.png"
                        alt="Logo"
                        width={logoSize}
                        height={logoSize}
                        preview={false}
                    />
                </div>
                {Config.isDevMode ? (
                    <Flex vertical align={'center'}>
                        <Title color="black" style={{ marginLeft: '4rem', marginBottom: -20 }}>
                            <b>DEVELOPMENT MODE</b>
                        </Title>
                        <Title
                            level={5}
                            style={{ marginLeft: '4rem', marginBottom: 0, whiteSpace: 'pre-line' }}
                        >
                            <b>Session ID:</b> {Auth.sessionId()} <b>Commit hash:</b>{' '}
                            {process.env.REACT_APP_GIT_TAG}
                        </Title>
                    </Flex>
                ) : (
                    <></>
                )}
                {!isLoggedIn && (
                    <div className="space-x-4">
                        <Button
                            type={'primary'}
                            shape="round"
                            className={styles.loginButton}
                            onClick={handleSignUpButtonClick}
                            hidden={false}
                        >
                            {Config.signUp.buttonText}
                        </Button>
                        <Modal
                            open={showSignUpModal}
                            afterOpenChange={handleModalShowChange}
                            footer={null}
                            title={null}
                            maskClosable={true}
                            className={styles.bannerModal}
                            styles={{ body: { padding: 0 } }}
                            centered={true}
                            onCancel={() => setShowSignUpModal(false)}
                            modalRender={(modal) => (
                                <Flex align={'center'} justify={'center'} className={'h-full'}>
                                    {modal}
                                </Flex>
                            )}
                            width={620}
                            closeIcon={null}
                        >
                            <SignUp onLogin={handleSignUp} />
                        </Modal>
                    </div>
                )}
                {isLoggedIn && (
                    <Flex className="space-x-4" align={'center'}>
                        <Dropdown
                            open={showSignOutDropdown}
                            onOpenChange={(open) => setShowSignOutDropdown(open)}
                            trigger={['click', 'hover']}
                            placement={'bottom'}
                            dropdownRender={() => (
                                <SignOut
                                    onSignOut={onSignOut ? onSignOut : () => {}}
                                    userName={Auth.getUser().name}
                                />
                            )}
                            align={{ offset: [-20, 8] }}
                        >
                            <Button
                                ref={signOutButtonRef}
                                type="primary"
                                shape="circle"
                                className={styles.signOutButton}
                                onClick={handleSignOut}
                                hidden={false}
                            >
                                {avatarSrc !== '' ? (
                                    <Avatar
                                        src={avatarSrc}
                                        className={styles.avatar}
                                        crossOrigin={'anonymous'}
                                        onError={() => {
                                            Logger.error('Error in avatar: ');
                                            return false;
                                        }}
                                    />
                                ) : (
                                    <Avatar
                                        icon={<UserOutlined />}
                                        className={styles.avatarDefault}
                                    />
                                )}
                            </Button>
                        </Dropdown>
                    </Flex>
                )}
            </div>
        </Header>
    );
};

export default HeaderComponent;
