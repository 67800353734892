import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { QuestionMessageType } from '../../ChatbotTypes';
import ChatMessage from '../ChatMessage';

import styles from '../../../../styles/screen/SplitScreen/chat/ChatMessage.module.css';
import questionMessageStyles from '../../../../styles/screen/SplitScreen/chat/QuestionMessage.module.css';
import Typist from 'react-typist-component';
import Config from '../../../../config/config';

export interface QuestionMessageProps {
    msg: QuestionMessageType;
    onOptionClick: (question: string, answer: string) => void;
    isTyping: boolean;
    onTypingComplete?: () => void;
}

const QuestionMessage: React.FC<QuestionMessageProps> = ({
    msg,
    onOptionClick,
    isTyping,
    onTypingComplete,
}) => {
    const [msgTypingComplete, setMsgTypingComplete] = useState(false);
    const [visibleButtons, setVisibleButtons] = useState(0);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (msgTypingComplete && visibleButtons < msg.options.length) {
            timeout = setTimeout(() => {
                setVisibleButtons((prev) => prev + 1);
            }, Config.chat.questionMsgButtonAppearDelay); // Adjust this delay as needed
        }
        return () => clearTimeout(timeout);
    }, [msgTypingComplete, visibleButtons, msg.options.length]);

    const handleMessageTypingComplete = () => {
        setMsgTypingComplete(true);
    };

    const handleOptionsTypingComplete = () => {
        if (onTypingComplete) {
            onTypingComplete();
        }
    };

    return (
        <ChatMessage msg={msg} isTyping={isTyping} onTypingComplete={handleMessageTypingComplete}>
            {msgTypingComplete && (
                <>
                    <div className="mt-2 flex flex-wrap gap-2">
                        {msg.options
                            .slice(0, visibleButtons)
                            .map((option: string, index: number) => (
                                <Button
                                    key={index}
                                    onClick={() => onOptionClick(msg.question, option)}
                                    className={`h-auto whitespace-normal text-center ${questionMessageStyles.animateFadeIn} leading-normal`}
                                >
                                    <span
                                        className={styles.chatMsgOptionsFont}
                                        style={{
                                            wordBreak: 'break-word',
                                            display: 'block',
                                        }}
                                    >
                                        {option}
                                    </span>
                                </Button>
                            ))}
                    </div>
                    {visibleButtons === msg.options.length && (
                        <div className="mt-2 italic">
                            <Typist
                                typingDelay={Config.chat.typistTypingDelay}
                                hideCursorWhenDone={true}
                                cursor={undefined}
                                onTypingDone={handleOptionsTypingComplete}
                            >
                                {Config.chat.addYourOwnMsg}
                            </Typist>
                        </div>
                    )}
                </>
            )}
        </ChatMessage>
    );
};

export default QuestionMessage;
