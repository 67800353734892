import React from 'react';
import { Typography, Flex } from 'antd';
import styles from '../styles/screen/HomeScreen/ClickableCard.module.css';
import CircleButton from './CircleButton';

const { Text } = Typography;

export interface JourneySuggestionProps {
    title: string;
    subtitle: string;
    onClick?: () => void;
}

const ClickableCard: React.FC<JourneySuggestionProps> = ({ title, subtitle, onClick }) => {
    return (
        <div className={styles.card} onClick={onClick}>
            <Flex vertical justify={'space-between'} flex="1 1">
                <Text className={styles.cardTitle}>{title}</Text>
                <Flex justify={'space-between'} gap="10px" align="center">
                    <Text className={styles.cardSubtitle}>{subtitle}</Text>
                    <CircleButton iconSize="25px" onClick={onClick} />
                </Flex>
            </Flex>
        </div>
    );
};

export default ClickableCard;
