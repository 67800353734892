import React, { useEffect } from 'react';
import { MessageRole, IMessage } from '../ChatbotTypes';
import { Avatar, Flex, Spin } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import styles from '../../../styles/screen/SplitScreen/chat/ChatMessage.module.css';
import Typist from 'react-typist-component';
import Config from '../../../config/config';
import Auth from '../../../auth/Auth';

export interface ChatMessageProps {
    msg: IMessage;
    children?: React.ReactNode;
    isTyping: boolean;
    onTypingComplete?: () => void;
}

const ChatMessage: React.FC<ChatMessageProps> = ({ msg, children, isTyping, onTypingComplete }) => {
    const [avatarSrc, setAvatarSrc] = React.useState<string>('');

    useEffect(() => {
        Auth.getAvatarPictureURL().then((avatar) => {
            setAvatarSrc(avatar);
        });
    }, []);

    const isUser = msg.role === MessageRole.User;

    const handleTypingComplete = () => {
        if (onTypingComplete) {
            onTypingComplete();
        }
    };

    return (
        <Flex gap="10px" align="start" className={`${!isUser ? 'flex-row' : 'flex-row-reverse'}`}>
            <Flex>
                <Avatar
                    icon={<UserOutlined style={{ color: '#8c8c8c' }} />}
                    src={isUser ? (avatarSrc ? avatarSrc : undefined) : '/graphics/robot.png'}
                    size={40}
                    className={`${isUser ? 'bg-white border-[#8c8c8c]' : 'bg-[#f1f5f9] border-[#e5e7eb]'}`}
                />
            </Flex>
            <Flex
                vertical
                className={`rounded-lg p-3 border ${isUser ? 'bg-white border-[#8c8c8c] text-black' : 'bg-slate-100 text-black'}`}
            >
                {msg.isLoading ? (
                    <Spin />
                ) : msg.role === MessageRole.Bot ? (
                    <Typist
                        typingDelay={Config.chat.typistTypingDelay}
                        hideCursorWhenDone={true}
                        splitter={(str) => str.split('')}
                        cursor={undefined}
                        onTypingDone={handleTypingComplete}
                    >
                        <div className={styles.chatMsgFont}>{msg.text}</div>
                    </Typist>
                ) : (
                    <span className={styles.chatMsgFont} data-hj-allow>
                        {msg.text}
                    </span>
                )}
                {children}
                <span className="flex justify-end text-xs mt-1 opacity-50">
                    {msg.date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                </span>
            </Flex>
        </Flex>
    );
};

export default ChatMessage;
