import { createClient, EntrySkeletonType } from 'contentful';
import { ChainModifiers, ContentfulClientApi } from 'contentful/dist/types/types/client';
import { CONTENTFUL_CONFIG } from '../config/constants';
import { ResolveType } from './contentfulTypeResolve';
import Logger from '../logging/logger';
import { LocaleCode } from 'contentful/dist/types/types/locale';
import { Entry } from 'contentful/dist/types/types/entry';
import { AppConfiguration } from '../config/configuration.types';

class Contentful {
    private static client: ContentfulClientApi<any>;
    public static configurations: AppConfiguration[] | null;
    private static initialized: boolean = false;

    static async init(spaceId: string, accessToken: string): Promise<boolean> {
        try {
            Contentful.client = createClient({
                space: spaceId,
                accessToken: accessToken,
                environment: CONTENTFUL_CONFIG.ENVIRONMENT,
                host: 'cdn.contentful.com',
            });

            Contentful.configurations = await Contentful.fetchConfigurations();
            Contentful.initialized = true;
        } catch {
            throw new Error(
                'Failed to load content from contentful. Env name: ' +
                    (CONTENTFUL_CONFIG.ENVIRONMENT || 'Non existant')
            );
        }
        return Contentful.initialized;
    }

    public static getConfigNum(): number {
        if (Contentful.initialized && Contentful.configurations) {
            return Contentful.configurations.length;
        }
        return 0;
    }

    public static async fetchEntry<T extends EntrySkeletonType<any>>(
        entryId: string
    ): Promise<ResolveType<T['fields']> | null> {
        if (Contentful.initialized) {
            try {
                const entry = await Contentful.client.getEntry<T>(entryId, {
                    include: CONTENTFUL_CONFIG.REFERENCE_INCLUDE_DEPTH as any,
                });
                return Contentful.parseEntry(entry);
            } catch (error) {
                Logger.error('Error fetching from Contentful:', error);
                throw error;
            }
        } else {
            return null;
        }
    }

    private static parseEntry<
        ES extends EntrySkeletonType,
        M extends ChainModifiers,
        L extends LocaleCode,
    >(entry: Entry<ES, M, L>) {
        return Object.entries(entry.fields).reduce((acc, [key, value]) => {
            if (Array.isArray(value)) {
                acc[key] = value.map((item) => {
                    if (item && typeof item === 'object' && 'fields' in item) {
                        return item.fields;
                    }
                    return item;
                });
            } else if (value && typeof value === 'object') {
                if ('fields___' in value) {
                    acc[key] = value.fields;
                } else {
                    acc[key] = value;
                }
            } else {
                acc[key] = value;
            }
            return acc;
        }, {} as any);
    }

    private static async fetchEntries(type: string) {
        return await Contentful.client.getEntries({ content_type: type });
    }

    private static async fetchConfigurations(): Promise<AppConfiguration[] | null> {
        const configurations = await Contentful.fetchEntries(
            CONTENTFUL_CONFIG.TYPE_IDS.CONFIGURATION
        );
        if (configurations) {
            return configurations.items.map((item) => Contentful.parseEntry(item));
        } else {
            return null;
        }
    }
}

export default Contentful;
