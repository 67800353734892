import React, { useEffect } from 'react';
import styles from '../../styles/screen/HomeScreen/SearchBox.module.css';
import Typist from 'react-typist-component';

interface TyperProps {
    texts: string[];
    delayBetweenTexts: number;
}

const Typer: React.FC<TyperProps> = ({ texts, delayBetweenTexts }) => {
    const [currentTextIndex, setCurrentTextIndex] = React.useState<number>(0);
    const [isTypingDone, setIsTypingDone] = React.useState<boolean>(false);

    useEffect(() => {
        if (texts.length === 0 || !isTypingDone) {
            return;
        }

        requestAnimationFrame(() => {
            setCurrentTextIndex((prev) => (prev + 1) % texts.length);
            setIsTypingDone(false);
        });
    }, [texts.length, isTypingDone]);

    return (
        <Typist
            key={currentTextIndex}
            cursor={<span className={styles.cursor}>|</span>}
            typingDelay={30}
            startDelay={0}
            splitter={(str) => str.split('')}
            onTypingDone={() => {
                setTimeout(() => {
                    setIsTypingDone(true);
                }, delayBetweenTexts);
            }}
        >
            {texts[currentTextIndex]}
            <Typist.Delay ms={delayBetweenTexts} />
        </Typist>
    );
};

export default Typer;
