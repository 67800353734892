import Auth from '../auth/Auth';
import URLParams from '../core/URLHandler';

export interface UserData {
    screenResolution: string;
    viewportSize: string;
    timezone: string;
    language: string;
    userAgent: string;
    platform: string;
    cookiesEnabled: boolean;
    doNotTrack: string | null;
    sessionId?: string;
    userId?: string;
    userToken?: string;
    commitHash?: string;
    getUrlParams?: string[];
}

export function collectUserData(): UserData {
    return {
        screenResolution: `${window.screen.width}x${window.screen.height}`,
        viewportSize: `${window.innerWidth}x${window.innerHeight}`,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        language: navigator.language,
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        cookiesEnabled: navigator.cookieEnabled,
        doNotTrack: navigator.doNotTrack,
        sessionId: Auth.sessionId(),
        userId: Auth.getUser().id,
        userToken: Auth.getUserToken(),
        getUrlParams: URLParams.getUrlParams(),
        commitHash: process.env.REACT_APP_GIT_TAG,
    };
}
