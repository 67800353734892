import React from 'react';
import { IMessage } from '../ChatbotTypes';
import ChatMessage from './ChatMessage';

export interface StandardMessageProps {
    msg: IMessage;
    isTyping: boolean;
    onTypingComplete?: () => void;
}

const StandardMessage: React.FC<StandardMessageProps> = ({ msg, isTyping, onTypingComplete }) => {
    return <ChatMessage msg={msg} isTyping={isTyping} onTypingComplete={onTypingComplete} />;
};

export default StandardMessage;
