import { Image } from 'antd';
import React from 'react';

interface WebsiteLogoProps {
    size: string | number;
}

const WebsiteLogo: React.FC<WebsiteLogoProps> = ({ size }) => {
    return (
        <Image
            src={'/graphics/logo.png'}
            alt={document.title}
            preview={false}
            width={size}
            height={size}
        />
    );
};

export default WebsiteLogo;
